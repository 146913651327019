/*
 * Company: Whiz
 * Connect with us: Website: www.whiz.bg | E-mail: sales@whiz.bg | Phone: +359 2 870 9449
 * Project: EFOCS
 * Module: Web Browser Application
 */
const walletMenuItem = {
    EN: "Wallet",
    BG: "Портфейл"
}
const dashboardMenuItem = {
    EN: "Dashboard",
    BG: "Табло"
}
const tradingMenuItem = {
    EN: "Trading",
    BG: "Търговия"
}
const profileLabel = {
    EN: "Profile",
    BG: "Профил"
}
const balanceSummaryLabel = {
    EN: "My Total Balance",
    BG: "Моят общ баланс"
}
const accountSummaryLabel = {
    EN: "Account Summary",
    BG: "Баланс по сметка"
}
const myWalletLabel = {
    EN: "My Wallet",
    BG: "Моят портфейл"
}
const moneyLabel = {
    EN: "Cash",
    BG: "Пари"
}
const positionsMenuItem = {
    EN: "Positions",
    BG: "Позиции"
}
const positionsLabel = {
    EN: "Positions",
    BG: "Позиции"
}
const portfolioLabel = {
    EN: "Portfolio",
    BG: "Портфолио"
}
const myPositionsLabel = {
    EN: "My Positions",
    BG: "Моите позиции"
}
const myNumberOfInstrumentsLabel = {
    EN: "Number of Instruments",
    BG: "Брой позиции"
}
const buyLabel = {
    EN: "Buy",
    BG: "Купи"
}
const sellLabel = {
    EN: "Sell",
    BG: "Продай"
}
const buysLabel = {
    EN: "Buy",
    BG: "Купува"
}
const sellsLabel = {
    EN: "Sell",
    BG: "Продава"
}
const processingLabel = {
    EN: "Processing",
    BG: "Обработва се"
}
const placedLabel = {
    EN: "Placed",
    BG: "Подадена"
}
const filledLabel = {
    EN: "Filled",
    BG: "Изпълнена"
}
const acquiredLabel = {
    EN: "Acquired",
    BG: "Изпратена"
}
const acceptedLabel = {
    EN: "Accepted",
    BG: "Приета"
}
const passedLabel = {
    EN: "Passed",
    BG: "Приета"
}
const cancelledLabel = {
    EN: "Cancelled",
    BG: "Отказана"
}
const annulledLabel = {
    EN: "Annulled",
    BG: "Анулирана"
}
const executedLabel = {
    EN: "Executed",
    BG: "Изпълнена"
}
const completedLabel = {
    EN: "Filled",
    BG: "Завършена"
}
const partialLabel = {
    EN: "Partial",
    BG: "Частична"
}
const searchPlaceholder = {
    EN: "Search (at least 3 symbols)",
    BG: "Търси (поне 3 символа)"
}
const stockLabel = {
    EN: "Instrument",
    BG: "Инструмент"
}
const lastDealLabel = {
    EN: "Last Deal",
    BG: "Последна сделка"
}
const turnoverLabel = {
    EN: "Turnover",
    BG: "Оборот"
}
const gainersLabel = {
    EN: "Gainers",
    BG: "Печеливши"
}
const indexMembersLabel = {
    EN: "Choose Index",
    BG: "Избери индекс"
}
const losersLabel = {
    EN: "Losers",
    BG: "Губещи"
}
const mostTradedLabel = {
    EN: "Most Actives",
    BG: "Най-търгувани"
}
const indexLabel = {
    EN: "Index",
    BG: "Индекс"
}
const indexesLabel = {
    EN: "Indices",
    BG: "Индекси"
}
const changeLabel = {
    EN: "Change",
    BG: "Промяна"
}
const performanceLabel = {
    EN: "Performance",
    BG: "Представяне"
}
const valueLabel = {
    EN: "Value",
    BG: "Стойност"
}
const deleteWatchlistLabel = {
    EN: "You are about to delete this watchlist",
    BG: "Ще изтриеш този списък"
}
const closeLabel = {
    EN: "Close",
    BG: "Затвори"
}
const confirmLabel = {
    EN: "Confirm",
    BG: "Потвърди"
}
const couldNotSetExchangesMessage = {
    EN: "We're having trouble fetching data. Please try again.",
    BG: "Има проблем с извличането на данните. Опитай пак."
}
const couldNotReadMessage = {
    EN: "Could not read info for ",
    BG: "Не бяха получени данните за "
}
const couldNotReadIndexesMessage = {
    EN: "Could not read indexes info",
    BG: "Данните за индексите не бяха получени"
}
const couldNotReadWalletMessage = {
    EN: "Could not read wallet info",
    BG: "Данните за портфейла не бяха получени"
}
const couldNotReadQuotesMessage = {
    EN: "Could not read quotes info",
    BG: "Данните за котировките не бяха получени"
}
const couldNotSetSseMessage = {
    EN: "Could not set SSE info",
    BG: "Командата за настройка на актуализациите не беше изпълнена"
}
const couldNotDeleteWatchlistMessage = {
    EN: "Could not delete watchlist",
    BG: "Списъкът не беше изтрит"
}
const allExchangesLabel = {
    EN: "All Exchanges",
    BG: "Всички борси"
}
const allCurrenciesLabel = {
    EN: "All Currencies",
    BG: "Всички валути"
}
const exchangeLabel = {
    EN: "Exchange",
    BG: "Борса"
}
const closedLabel = {
    EN: "Closed",
    BG: "Затворен"
}
const openedLabel = {
    EN: "Open",
    BG: "Отворен"
}
const extendedInfoLabel = {
    EN: "Extended Info",
    BG: "Допълнителна информация"
}
const depthLabel = {
    EN: "Market Depth",
    BG: "Дълбочина на пазара"
}
const graphLabel = {
    EN: "Chart",
    BG: "Графика"
}
const exchangePhaseLabel = {
    EN: "Market is",
    BG: "Пазарът е"
}
const exchangePricesLabel = {
    EN: "Prices are in",
    BG: "Цените са в"
}
const previousCloseLabel = {
    EN: "Previous Close",
    BG: "Предишно затваряне"
}
const dailyVolumeLabel = {
    EN: "Daily Volume",
    BG: "Дневен обем"
}
const quantityLabel = {
    EN: "Quantity",
    BG: "Количество"
}
const bidLabel = {
    EN: "Bid",
    BG: "Купува"
}
const askLabel = {
    EN: "Ask",
    BG: "Продава"
}
const oneYearReturnLabel = {
    EN: "1 Year Return",
    BG: "Възвръщаемост за посл. 1 год."
}
const marketCapLabel = {
    EN: "Market Cap",
    BG: "Пазарна капитализация"
}
const lastTransactionLabel = {
    EN: "Last Transaction",
    BG: "Последна трансакция"
}
const ordersMenuItem = {
    EN: "Orders",
    BG: "Поръчки"
}
const ordersLabel = {
    EN: "Orders",
    BG: "Поръчки"
}
const orderLabel = {
    EN: "Order ID",
    BG: "Поръчка"
}
const orderPlacedLabel = {
    EN: "Order Placed",
    BG: "Направена поръчка"
}
const orderTypeLabel = {
    EN: "Order Type",
    BG: "Тип на поръчка"
}
const tradeLabel = {
    EN: "Trade",
    BG: "Търгувай"
}
const couldNotReadPhasesMessage = {
    EN: "Could not read exchanges phases",
    BG: "Борсовите фази не бяха получени"
}
const couldNotReadHistoryMessage = {
    EN: "Could not read instrument history",
    BG: "Данните за промените на инструмента не бяха получени"
}
const couldNotSetQuoteMessage = {
    EN: "Could not set quote data",
    BG: "Командата за актуализация на данните за инструмента не беше изпълнена"
}
const watchlistSelectionLabel = {
    EN: "Choose Watchlist",
    BG: "Избери списък"
}
const saveLabel = {
    EN: "Save",
    BG: "Съхрани"
}
const newWatchlistNameLabel = {
    EN: "New Watchlist",
    BG: "Нов списък"
}
const existingWatchlistNameLabel = {
    EN: "Existing Watchlist",
    BG: "Съществуващ списък"
}
const watchlistLabel = {
    EN: "Watchlist",
    BG: "Списък"
}
const watchlistNamePlaceholder = {
    EN: "Enter name",
    BG: "Въведи име"
}
const couldNotUpdateWatchlistMessage = {
    EN: "Could not update watchlists info",
    BG: "Списъкът не беше обновен"
}
const stockNotAddedMessage = {
    EN: "Instrument not added to list",
    BG: "Инструментът не беше добавен към списъка"
}
const watchlistNameOccupiedMessage = {
    EN: "This watchlist name is already used. Please type another",
    BG: "Това име на списък вече е заето, моля използвай друго"
}
const addedToWatchlistMessage = {
    EN: "Instrument added to watchlist",
    BG: "Инструментът беше добавен към списък"
}
const generalInformationLabel = {
    EN: "General Information",
    BG: "Обща информация"
}
const securityInformationLabel = {
    EN: "Security Information",
    BG: "Сигурност"
}
const languageLabel = {
    EN: "Language",
    BG: "Език"
}
const languageMenuItem = {
    EN: "Language",
    BG: "Език"
}
const currencyLabel = {
    EN: "Currency",
    BG: "Валута"
}
const mainLabel = {
    EN: "Main",
    BG: "Информация"
}
const currencyMenuItem = {
    EN: "Currency",
    BG: "Валута"
}
const baseCurrencyLabel = {
    EN: "Base Currency",
    BG: "Основна валута"
}
const updateLabel = {
    EN: "Update",
    BG: "Промени"
}
const currentPasswordLabel = {
    EN: "Old Password",
    BG: "Стара парола"
}
const passwordMenuItem = {
    EN: "Password",
    BG: "Парола"
}
const passwordLabel = {
    EN: "Password",
    BG: "Парола"
}
const newPasswordLabel = {
    EN: "New Password",
    BG: "Нова парола"
}
const passwordPlaceHolder = {
    EN: "Enter Your Password",
    BG: "Въведи парола"
}
const repeatPasswordPlaceHolder = {
    EN: "Repeat New Password",
    BG: "Въведи новата парола отново"
}
const couldNotUpdatePasswordMessage = {
    EN: "Could not update your password",
    BG: "Паролата ти не беше актуализирана"
}
const passwordUpdatedMessage = {
    EN: "Your password has been changed successfully",
    BG: "Твоята парола беше актуализирана"
}
const couldNotUpdateLanguageMessage = {
    EN: "Could not update your language preference",
    BG: "Твоят предпочитан език не беше актуализиран"
}
const languageUpdatedMessage = {
    EN: "Your language has been changed successfully",
    BG: "Твоят предпочитан език беше актуализиран"
}
const currencyUpdatedMessage = {
    EN: "Your currency has been changed successfully",
    BG: "Твоята основна валута беше актуализирана"
}
const passwordResetMessage = {
    EN: "You have reset your EFOCS password. Please follow the link in your email.",
    BG: "Твоята парола беше подновена. Моля, последвай линка в изпратения имейл."
}
const confirmDeleteWatchlistLabel = {
    EN: "You are about to delete this watchlist",
    BG: "Ще изтриеш този списък"
}
const confirmCancelOrderLabel = {
    EN: "You are about to cancel this order",
    BG: "Ще отмениш тази поръчка"
}
const summaryMenuItem = {
    EN: "Summary",
    BG: "Начало"
}
const transactionsMenuItem = {
    EN: "Transactions",
    BG: "Движения"
}
const transactionsLabel = {
    EN: "Transactions",
    BG: "Движения"
}
const tradesMenuItem = {
    EN: "Trades",
    BG: "Сделки"
}
const tradesLabel = {
    EN: "Trades",
    BG: "Сделки"
}
const typeLabel = {
    EN: "Type",
    BG: "Тип"
}
const purchasePriceLabel = {
    EN: "Purchase Price",
    BG: "Покупна цена"
}
const dateLabel = {
    EN: "Date",
    BG: "Дата"
}
const timestampLabel = {
    EN: "Timestamp",
    BG: "Дата и час"
}
const priceТotalLabel = {
    EN: "Price total",
    BG: "Крайна цена"
}
const priceLabel = {
    EN: "Price",
    BG: "Цена"
}
const feeLabel = {
    EN: "Commission",
    BG: "Комисиона"
}
const noteLabel = {
    EN: "Note",
    BG: "Бележка"
}
const codeNameLabel = {
    EN: "Symbol/Name",
    BG: "Код/Име"
}
const quantityPriceLabel = {
    EN: "Quantity/Price",
    BG: "К-во/Цена"
}
const statusLabel = {
    EN: "Status",
    BG: "Статус"
}
const currentBalanceLabel = {
    EN: "Current Balance",
    BG: "Текущ баланс"
}
const balanceLabel = {
    EN: "Balance",
    BG: "Баланс"
}
const totalBalanceLabel = {
    EN: "Total Balance",
    BG: "Общ баланс"
}
const cashBalanceLabel = {
    EN: "Cash Balance",
    BG: "Паричен баланс"
}
const cashLabel = {
    EN: "Cash",
    BG: "Пари"
}
const inSettlementLabel = {
    EN: "In Settlement",
    BG: "В сетълмент"
}
const freeFundsLabel = {
    EN: "Free Funds",
    BG: "Свободни средства"
}
const guaranteeDepositLabel = {
    EN: "Guarantee Deposit",
    BG: "Гаранционен депозит"
}
const excessFundsLabel = {
    EN: "Excess Funds",
    BG: "Свободни за търговия"
}
const availableToWithdrawLabel = {
    EN: "Available to Withdraw",
    BG: "Налични за теглене"
}
const positionsBalanceLabel = {
    EN: "Positions Balance",
    BG: "Баланс инструменти"
}
const addFundsLabel = {
    EN: "Add Funds",
    BG: "Добави средства"
}
const numberOfSharesLabel = {
    EN: "Number of Shares",
    BG: "Брой инструменти"
}
const couldNotReadOrdersMessage = {
    EN: "Could not read user orders info",
    BG: "Поръчките не бяха прочетени"
}
const couldNotCancelOrderMessage = {
    EN: "Could not cancel order",
    BG: "Поръчката не беше отменена"
}
const cancelledOrderMessage = {
    EN: "Order cancelled",
    BG: "Поръчката беше отменена"
}
const couldNotReadTradesMessage = {
    EN: "Could not read user trades info",
    BG: "Сделките не бяха прочетени"
}
const informationLabel = {
    EN: "Information",
    BG: "Информация"
}
const codeLabel = {
    EN: "Symbol",
    BG: "Код"
}
const nameLabel = {
    EN: "Name",
    BG: "Име"
}
const marketPriceLabel = {
    EN: "Market Price",
    BG: "Пазарна цена"
}
const marketValueLabel = {
    EN: "Market value",
    BG: "Пазарна стойност"
}
const averagePriceLabel = {
    EN: "Average Purchase Price",
    BG: "Средна покупна цена"
}
const logoutMenuItem = {
    EN: "Logout",
    BG: "Изход"
}
const loginLabel = {
    EN: "Login",
    BG: "Вход"
}
const newNotificationsLabel = {
    EN: "New notifications",
    BG: "Нови известия"
}
const clearAllLabel = {
    EN: "Clear all",
    BG: "Премахни всички"
}
const clearLabel = {
    EN: "Clear",
    BG: "Изчисти"
}
const viewAllLabel = {
    EN: "View all",
    BG: "Виж всички"
}
const demoLabel = {
    EN: "DEMO",
    BG: "ДЕМО СМЕТКА"
}
const liveLabel = {
    EN: "LIVE",
    BG: "РЕАЛНА СМЕТКА"
}
const clientNumberLabel = {
    EN: "Client Number",
    BG: "Клиент №"
}
const messagesLabel = {
    EN: "Messages",
    BG: "Съобщения"
}
const messageLabel = {
    EN: "Message",
    BG: "Съобщение"
}
const itemsLabel = {
    EN: "items",
    BG: "елемента"
}
const addedToDefaultWatchlistMessage = {
    EN: "Instrument added to default watchlist",
    BG: "Инструментът беше добавен към списъка по подразбиране"
}
const removedFromWatchlistMessage = {
    EN: "Instrument removed from watchlist",
    BG: "Инструментът беше премахнат от списъка"
}
const watchlistContainsStockMessage = {
    EN: "Watchlist already contains this instrument",
    BG: "Списъкът вече съдържа този инструмент"
}
const noItemsInWatchlistMessage = {
    EN: "There are no items to display. Try selecting a watchlist",
    BG: "Няма инструменти за показване. Опитай да избереш списък"
}
const orderSentMessage = {
    EN: "Your order has been sent to exchange",
    BG: "Твоята поръчка е изпратена към борсата"
}
const orderNotSentMessage = {
    EN: "Your order has not been sent to exchange. Error # ",
    BG: "Твоята поръчка не изпратена към борсата. Грешка № "
}
const marketLabel = {
    EN: "Market",
    BG: "Пазарна"
}
const limitLabel = {
    EN: "Limit",
    BG: "Лимитирана"
}
const codePlaceholder = {
    EN: "Select Instrument to Update",
    BG: "Избери инструмент"
}
const dynamicRangeLabel = {
    EN: "Dynamic range",
    BG: "Динамичен диапазон"
}
const minPriceLabel = {
    EN: "Min. Price",
    BG: "Минимална цена"
}
const maxPriceLabel = {
    EN: "Max. Price",
    BG: "Максимална цена"
}
const tickSizeLabel = {
    EN: "Tick size",
    BG: "Размер на tick"
}
const youHaveLabel = {
    EN: "You have",
    BG: "Имаш"
}
const stocksLabel = {
    EN: "shares",
    BG: "инструмента"
}
const totalAmountLabel = {
    EN: "Total Amount",
    BG: "Обща стойност"
}
const purchasedAmountLabel = {
    EN: "Purchased Amount",
    BG: "Обща стойност"
}
const amountLabel = {
    EN: "Amount",
    BG: "Стойност"
}
const totalEstimatedAmountLabel = {
    EN: "Estimated Total Amount",
    BG: "Очаквана обща стойност"
}
const approximateAmountLabel = {
    EN: "Approximate Amount",
    BG: "Приблизителна стойност"
}
const estimatedAmountLabel = {
    EN: "Estimated Amount",
    BG: "Очаквана стойност"
}
const choseExpiryDateLabel = {
    EN: "Choose expiry date",
    BG: "Избери крайна дата за изпълнение"
}
const chooseValidityLabel = {
    EN: "Choose Validity",
    BG: "Избери валидност"
}
const validityLabel = {
    EN: "Validity",
    BG: "Валидност"
}
const dailyLabel = {
    EN: "Good for Day",
    BG: "За деня"
}
const daysLabel = {
    EN: "Days",
    BG: "дни"
}
const choseDateLabel = {
    EN: "Good Till Date",
    BG: "До дата"
}
const goodTillCancelledLabel = {
    EN: "Good Till Cancelled",
    BG: "До отмяна"
}
const restrictionsLabel = {
    EN: "Trade Restrictions",
    BG: "Ограничения"
}
const noRestrictionsLabel = {
    EN: "No Restrictions",
    BG: "Без ограничения"
}
const acceptTCLabel = {
    EN: "Accept Terms and Conditions",
    BG: "Приемам Условията"
}
const submitLabel = {
    EN: "Submit",
    BG: "Изпрати"
}
const cancelLabel = {
    EN: "Cancel",
    BG: "Откажи"
}
const searchModalLabel = {
    EN: "Search Instrument",
    BG: "Търси инструмент"
}
const sumLabel = {
    EN: "Amount",
    BG: "Сума"
}
const estimatedCommissionLabel = {
    EN: "Estimated Commission",
    BG: "Очаквана комисиона"
}
const estimatedTotalLabel = {
    EN: "Estimated Total",
    BG: "Очаквана стойност"
}
const tradeConfirmedLabel = {
    EN: "Trade Confirmed",
    BG: "Потвърдена сделка"
}
const commissionLabel = {
    EN: "Commission",
    BG: "Комисиона"
}
const reestablishingConnectionLabel = {
    EN: "Establishing a fresh connection. Hang tight...",
    BG: "Имай търпение! Възстановяваме връзката..."
}
const reestablishedConnectionLabel = {
    EN: "Your connection is back up and running.",
    BG: "Връзката е възстановена."
}
const usernamePlaceholder = {
    EN: "Username",
    BG: "Потребител"
}
const forgotPasswordLabel = {
    EN: "Forgot Password?",
    BG: "Забравена парола?"
}
const createDemoLabel = {
    EN: "Create Demo",
    BG: "Отвори демо сметка"
}
const createAccountLabel = {
    EN: "Create Account",
    BG: "Отвори сметка"
}
const dontHaveAccountLabel = {
    EN: "Don't have a live account?",
    BG: "Нямаш сметка?"
}
const dontHaveDemoAccountLabel = {
    EN: "Don't have a demo account?",
    BG: "Нямаш демо сметка?"
}
const rememberMeLabel = {
    EN: "Remember Me",
    BG: "Запомни ме"
}
const equitiesLabel = {
    EN: "Equities",
    BG: "Акции"
}
const bondsLabel = {
    EN: "Bonds",
    BG: "Облигации"
}
const compInstrumentsLabel = {
    EN: "Compensatory instruments",
    BG: "Компенсаторни инструменти"
}
const fundsLabel = {
    EN: "Funds",
    BG: "Дялове"
}
const reitsLabel = {
    EN: "REITS",
    BG: "АДСИЦ"
}
const rightsLabel = {
    EN: "Rights",
    BG: "Права"
}
const cfdLabel = {
    EN: "CFD",
    BG: "CFD"
}
const marginLabel = {
    EN: "Margin",
    BG: "Марджин"
}
const nettingLabel = {
    EN: "Netting",
    BG: "Нетинг"
}
const repoLabel = {
    EN: "Repo",
    BG: "Репо"
}
const assetLabel = {
    EN: "Asset",
    BG: "Тип"
}
const countLabel = {
    EN: "Count",
    BG: "Брой"
}
const noMatchingInstrumentsFoundLabel = {
    EN: "No matching financial instrument found :(",
    BG: "Не бяха открити съвпадащи финансови инструменти :("
}
const refineYourKeywordsLabel = {
    EN: "Please refine your search keywords",
    BG: "Моля уточнете ключовите си думи"
}
const detailsLabel = {
    EN: "Details",
    BG: "Подробности"
}
const phoneLabel = {
    EN: "Phone",
    BG: "Телефон"
}
const emailLabel = {
    EN: "Email",
    BG: "Email"
}
const chartDataForInstrumentUnavailableMessage = {
    EN: "Chart data for this financial instrument is unavailable",
    BG: "Графичните данни за този инструмент са недостъпни"
}
const sortSymbolAscLabel = {
    EN: "Symbol Ascending",
    BG: "Код, възходящ"
}
const sortSymbolDescLabel = {
    EN: "Symbol Descending",
    BG: "Код, низходящ"
}
const sortNameAscLabel = {
    EN: "Name Ascending",
    BG: "Име, възходящо"
}
const sortNameDescLabel = {
    EN: "Name Descending",
    BG: "Име, низходящо"
}
const sortDefaultLabel = {
    EN: "Default",
    BG: "По подразбиране"
}
const menuLabel = {
    EN: "Menu",
    BG: "Меню"
}
const welcomeToEfocsLabel = {
    EN: "Welcome to EFOCS!",
    BG: "Добре дошли в EFOCS!"
}
const loginToYourAccountLabel = {
    EN: "Your current session has expired. Please log in again to continue.",
    BG: "Текущата ти сесия е изтекла. Моля, влез отново, за да продължиш."
}
const invalidSessionLabel = {
    EN: "Your session has been disconnected. Another session with the same username has been detected.",
    BG: "Вашата сесия беше прекъсната. Има друга активна сесия със същото потребителско име."
}
const faceValueLabel = {
    EN: "Face Value",
    BG: "Номинал"
}
const segmentLabel = {
    EN: "Segment",
    BG: "Сегмент"
}
const returnLabel = {
    EN: "Return",
    BG: "Резултат"
}

function getWalletMenuItem(lang) {
    return walletMenuItem[lang];
}
function getDashboardMenuItem(lang) {
    return dashboardMenuItem[lang];
}
function getTradingMenuItem(lang) {
    return tradingMenuItem[lang];
}
function getProfileLabel(lang) {
    return profileLabel[lang];
}
function getBalanceSummaryLabel(lang) {
    return balanceSummaryLabel[lang];
}
function getAccountSummaryLabel(lang) {
    return accountSummaryLabel[lang];
}
function getMyWalletLabel(lang) {
    return myWalletLabel[lang];
}
function getMoneyLabel(lang) {
    return moneyLabel[lang];
}
function getPositionsLabel(lang) {
    return positionsLabel[lang];
}
function getPortfolioLabel(lang) {
    return portfolioLabel[lang];
}
function getMyPositionsLabel(lang) {
    return myPositionsLabel[lang];
}
function getMyNumberOfInstrumentsLabel(lang) {
    return myNumberOfInstrumentsLabel[lang];
}
function getPositionsMenuItem(lang) {
    return positionsMenuItem[lang];
}
function getBuyLabel(lang) {
    return buyLabel[lang];
}
function getSellLabel(lang) {
    return sellLabel[lang];
}
function getBuysLabel(lang) {
    return buysLabel[lang];
}
function getSellsLabel(lang) {
    return sellsLabel[lang];
}
function getProcessingLabel(lang) {
    return processingLabel[lang];
}
function getPlacedLabel(lang) {
    return placedLabel[lang];
}
function getFilledLabel(lang) {
    return filledLabel[lang];
}
function getAcquiredLabel(lang) {
    return acquiredLabel[lang];
}
function getAcceptedLabel(lang) {
    return acceptedLabel[lang];
}
function getPassedLabel(lang) {
    return passedLabel[lang];
}
function getAnnulledLabel(lang) {
    return annulledLabel[lang];
}
function getExecutedLabel(lang) {
    return executedLabel[lang];
}
function getCompletedLabel(lang) {
    return completedLabel[lang];
}
function getPartialLabel(lang) {
    return partialLabel[lang];
}
function getCancelledLabel(lang) {
    return cancelledLabel[lang];
}
function getSearchPlaceholder(lang) {
    return searchPlaceholder[lang];
}
function getStockLabel(lang) {
    return stockLabel[lang];
}
function getLastDealLabel(lang) {
    return lastDealLabel[lang];
}
function getTurnoverLabel(lang) {
    return turnoverLabel[lang];
}
function getGainersLabel(lang) {
    return gainersLabel[lang];
}
function getIndexMembersLabel(lang) {
    return indexMembersLabel[lang];
}
function getLosersLabel(lang) {
    return losersLabel[lang];
}
function getMostTradedLabel(lang) {
    return mostTradedLabel[lang];
}
function getIndexLabel(lang) {
    return indexLabel[lang];
}
function getIndexesLabel(lang) {
    return indexesLabel[lang];
}
function getChangeLabel(lang) {
    return changeLabel[lang];
}
function getPerformanceLabel(lang) {
    return performanceLabel[lang];
}
function getValueLabel(lang) {
    return valueLabel[lang];
}
function getDeleteWatchlistLabel(lang) {
    return deleteWatchlistLabel[lang];
}
function getCloseLabel(lang) {
    return closeLabel[lang];
}
function getConfirmLabel(lang) {
    return confirmLabel[lang];
}
function getCouldNotSetExchangesMessage(lang) {
    return couldNotSetExchangesMessage[lang];
}
function getCouldNotReadMessage(lang) {
    return couldNotReadMessage[lang];
}
function getCouldNotReadIndexesMessage(lang) {
    return couldNotReadIndexesMessage[lang];
}
function getCouldNotReadWalletMessage(lang) {
    return couldNotReadWalletMessage[lang];
}
function getCouldNotReadQuotesMessage(lang) {
    return couldNotReadQuotesMessage[lang];
}
function getCouldNotSetSseMessage(lang) {
    return couldNotSetSseMessage[lang];
}
function getCouldNotDeleteWatchlistMessage(lang) {
    return couldNotDeleteWatchlistMessage[lang];
}
function getAllExchangesLabel(lang) {
    return allExchangesLabel[lang];
}
function getАllCurrenciesLabel(lang) {
    return allCurrenciesLabel[lang];
}
function getClosedLabel(lang) {
    return closedLabel[lang];
}
function getOpenedLabel(lang) {
    return openedLabel[lang];
}
function getExtendedInfoLabel(lang) {
    return extendedInfoLabel[lang];
}
function getDepthLabel(lang) {
    return depthLabel[lang];
}
function getGraphLabel(lang) {
    return graphLabel[lang];
}
function getExchangeLabel(lang) {
    return exchangeLabel[lang];
}
function getExchangePhaseLabel(lang) {
    return exchangePhaseLabel[lang];
}
function getExchangePricesLabel(lang) {
    return exchangePricesLabel[lang];
}
function getPreviousCloseLabel(lang) {
    return previousCloseLabel[lang];
}
function getDailyVolumeLabel(lang) {
    return dailyVolumeLabel[lang];
}
function getQuantityLabel(lang) {
    return quantityLabel[lang];
}
function getBidLabel(lang) {
    return bidLabel[lang];
}
function getAskLabel(lang) {
    return askLabel[lang];
}
function getOneYearReturnLabel(lang) {
    return oneYearReturnLabel[lang];
}
function getMarketCapLabel(lang) {
    return marketCapLabel[lang];
}
function getLastTransactionLabel(lang) {
    return lastTransactionLabel[lang];
}
function getOrdersLabel(lang) {
    return ordersLabel[lang];
}
function getOrdersMenuItem(lang) {
    return ordersMenuItem[lang];
}
function getOrderLabel(lang) {
    return orderLabel[lang];
}
function getOrderPlacedLabel(lang) {
    return orderPlacedLabel[lang];
}
function getOrderTypeLabel(lang) {
    return orderTypeLabel[lang];
}
function getPurchasePriceLabel(lang) {
    return purchasePriceLabel[lang];
}
function getTradeLabel(lang) {
    return tradeLabel[lang];
}
function getCouldNotReadPhasesMessage(lang) {
    return couldNotReadPhasesMessage[lang];
}
function getCouldNotReadHistoryMessage(lang) {
    return couldNotReadHistoryMessage[lang];
}
function getCouldNotSetQuoteMessage(lang) {
    return couldNotSetQuoteMessage[lang];
}
function getWatchlistSelectionLabel(lang) {
    return watchlistSelectionLabel[lang];
}
function getSaveLabel(lang) {
    return saveLabel[lang];
}
function getNewWatchlistNameLabel(lang) {
    return newWatchlistNameLabel[lang];
}
function getExistingWatchlistNameLabel(lang) {
    return existingWatchlistNameLabel[lang];
}
function getWatchlistLabel(lang) {
    return watchlistLabel[lang];
}
function getWatchlistNamePlaceholder(lang) {
    return watchlistNamePlaceholder[lang];
}
function getCouldNotUpdateWatchlistMessage(lang) {
    return couldNotUpdateWatchlistMessage[lang];
}
function getStockNotAddedMessage(lang) {
    return stockNotAddedMessage[lang];
}
function getWatchlistNameOccupiedMessage(lang) {
    return watchlistNameOccupiedMessage[lang];
}
function getAddedToWatchlistMessage(lang) {
    return addedToWatchlistMessage[lang];
}
function getGeneralInformationLabel(lang) {
    return generalInformationLabel[lang];
}
function getSecurityInformationLabel(lang) {
    return securityInformationLabel[lang];
}
function getLanguageLabel(lang) {
    return languageLabel[lang];
}
function getLanguageMenuItem(lang) {
    return languageMenuItem[lang];
}
function getCurrencyLabel(lang) {
    return currencyLabel[lang];
}
function getMainLabel(lang) {
    return mainLabel[lang];
}
function getCurrencyMenuItem(lang) {
    return currencyMenuItem[lang];
}
function getBaseCurrencyLabel(lang) {
    return baseCurrencyLabel[lang];
}
function getUpdateLabel(lang) {
    return updateLabel[lang];
}
function getCurrentPasswordLabel(lang) {
    return currentPasswordLabel[lang];
}
function getPasswordLabel(lang) {
    return passwordLabel[lang];
}
function getPasswordMenuItem(lang) {
    return passwordMenuItem[lang];
}
function getNewPasswordLabel(lang) {
    return newPasswordLabel[lang];
}
function getPasswordPlaceHolder(lang) {
    return passwordPlaceHolder[lang];
}
function getRepeatPasswordPlaceHolder(lang) {
    return repeatPasswordPlaceHolder[lang];
}
function getCouldNotUpdatePasswordMessage(lang) {
    return couldNotUpdatePasswordMessage[lang];
}
function getPasswordUpdatedMessage(lang) {
    return passwordUpdatedMessage[lang];
}
function getCouldNotUpdateLanguageMessage(lang) {
    return couldNotUpdateLanguageMessage[lang];
}
function getLanguageUpdatedMessage(lang) {
    return languageUpdatedMessage[lang];
}
function getCurrencyUpdatedMessage(lang) {
    return currencyUpdatedMessage[lang];
}
function getPasswordResetMessage(lang) {
    return passwordResetMessage[lang];
}
function getConfirmDeleteWatchlistLabel(lang) {
    return confirmDeleteWatchlistLabel[lang];
}
function getConfirmCancelOrderLabel(lang) {
    return confirmCancelOrderLabel[lang];
}
function getSummaryMenuItem(lang) {
    return summaryMenuItem[lang];
}
function getTransactionsLabel(lang) {
    return transactionsLabel[lang];
}
function getTransactionsMenuItem(lang) {
    return transactionsMenuItem[lang];
}
function getTradesLabel(lang) {
    return tradesLabel[lang];
}
function getTradesMenuItem(lang) {
    return tradesMenuItem[lang];
}
function getTypeLabel(lang) {
    return typeLabel[lang];
}
function getDateLabel(lang) {
    return dateLabel[lang];
}
function getTimestampLabel(lang) {
    return timestampLabel[lang];
}
function getPriceТotalLabel(lang) {
    return priceТotalLabel[lang];
}
function getPriceLabel(lang) {
    return priceLabel[lang];
}
function getFeeLabel(lang) {
    return feeLabel[lang];
}
function getNoteLabel(lang) {
    return noteLabel[lang];
}
function getCodeNameLabel(lang) {
    return codeNameLabel[lang];
}
function getQuantityPriceLabel(lang) {
    return quantityPriceLabel[lang];
}
function getStatusLabel(lang) {
    return statusLabel[lang];
}
function getCurrentBalanceLabel(lang) {
    return currentBalanceLabel[lang];
}
function getBalanceLabel(lang) {
    return balanceLabel[lang];
}
function getTotalBalanceLabel(lang) {
    return totalBalanceLabel[lang];
}
function getCashBalanceLabel(lang) {
    return cashBalanceLabel[lang];
}
function getCashLabel(lang) {
    return cashLabel[lang];
}
function getInSettlementLabel(lang) {
    return inSettlementLabel[lang];
}
function getFreeFundsLabel(lang) {
    return freeFundsLabel[lang];
}
function getGuaranteeDepositLabel(lang) {
    return guaranteeDepositLabel[lang];
}
function getExcessFundsLabel(lang) {
    return excessFundsLabel[lang];
}
function getAvailableToWithdrawLabel(lang) {
    return availableToWithdrawLabel[lang];
}
function getPositionsBalanceLabel(lang) {
    return positionsBalanceLabel[lang];
}
function getAddFundsLabel(lang) {
    return addFundsLabel[lang];
}
function getNumberOfSharesLabel(lang) {
    return numberOfSharesLabel[lang];
}
function getCouldNotReadOrdersMessage(lang) {
    return couldNotReadOrdersMessage[lang];
}
function getCouldNotCancelOrderMessage(lang) {
    return couldNotCancelOrderMessage[lang];
}
function getCancelledOrderMessage(lang) {
    return cancelledOrderMessage[lang];
}
function getCouldNotReadTradesMessage(lang) {
    return couldNotReadTradesMessage[lang];
}
function getInformationLabel(lang) {
    return informationLabel[lang];
}
function getCodeLabel(lang) {
    return codeLabel[lang];
}
function getNameLabel(lang) {
    return nameLabel[lang];
}
function getMarketPriceLabel(lang) {
    return marketPriceLabel[lang];
}
function getMarketValueLabel(lang) {
    return marketValueLabel[lang];
}
function getAveragePriceLabel(lang) {
    return averagePriceLabel[lang];
}
function getLogoutMenuItem(lang) {
    return logoutMenuItem[lang];
}
function getLoginLabel(lang) {
    return loginLabel[lang];
}
function getNewNotificationsLabel(lang) {
    return newNotificationsLabel[lang];
}
function getClearAllLabel(lang) {
    return clearAllLabel[lang];
}
function getClearLabel(lang) {
    return clearLabel[lang];
}
function getViewAllLabel(lang) {
    return viewAllLabel[lang];
}
function getDemoLabel(lang) {
    return demoLabel[lang];
}
function getLiveLabel(lang) {
    return liveLabel[lang];
}
function getClientNumberLabel(lang) {
    return clientNumberLabel[lang];
}
function getMessagesLabel(lang) {
    return messagesLabel[lang];
}
function getMessageLabel(lang) {
    return messageLabel[lang];
}
function getItemsLabel(lang) {
    return itemsLabel[lang];
}
function getAddedToDefaultWatchlistMessage(lang) {
    return addedToDefaultWatchlistMessage[lang];
}
function getRemovedFromWatchlistMessage(lang) {
    return removedFromWatchlistMessage[lang];
}
function getWatchlistContainsStockMessage(lang) {
    return watchlistContainsStockMessage[lang];
}
function getNoItemsInWatchlistMessage(lang) {
    return noItemsInWatchlistMessage[lang];
}
function getOrderSentMessage(lang) {
    return orderSentMessage[lang];
}
function getOrderNotSentMessage(lang) {
    return orderNotSentMessage[lang];
}
function getMarketLabel(lang) {
    return marketLabel[lang];
}
function getLimitLabel(lang) {
    return limitLabel[lang];
}
function getCodePlaceholder(lang) {
    return codePlaceholder[lang];
}
function getDynamicRangeLabel(lang) {
    return dynamicRangeLabel[lang];
}
function getMinPriceLabel(lang) {
    return minPriceLabel[lang];
}
function getMaxPriceLabel(lang) {
    return maxPriceLabel[lang];
}
function getTickSizeLabel(lang) {
    return tickSizeLabel[lang];
}
function getYouHaveLabel(lang) {
    return youHaveLabel[lang];
}
function getStocksLabel(lang) {
    return stocksLabel[lang];
}
function getTotalAmountLabel(lang) {
    return totalAmountLabel[lang];
}
function getPurchasedAmountLabel(lang) {
    return purchasedAmountLabel[lang];
}
function getAmountLabel(lang) {
    return amountLabel[lang];
}
function getTotalEstimatedAmountLabel(lang) {
    return totalEstimatedAmountLabel[lang];
}
function getApproximateAmountLabel(lang) {
    return approximateAmountLabel[lang];
}
function getEstimatedAmountLabel(lang) {
    return estimatedAmountLabel[lang];
}
function getChoseExpiryDateLabel(lang) {
    return choseExpiryDateLabel[lang];
}
function getChooseValidityLabel(lang) {
    return chooseValidityLabel[lang];
}
function getValidityLabel(lang) {
    return validityLabel[lang];
}
function getDailyLabel(lang) {
    return dailyLabel[lang];
}
function getDaysLabel(lang) {
    return daysLabel[lang];
}
function getChoseDateLabel(lang) {
    return choseDateLabel[lang];
}
function getGoodTillCancelledLabel(lang) {
    return goodTillCancelledLabel[lang];
}
function getRestrictionsLabel(lang) {
    return restrictionsLabel[lang];
}
function getNoRestrictionsLabel(lang) {
    return noRestrictionsLabel[lang];
}
function getAcceptTCLabel(lang) {
    return acceptTCLabel[lang];
}
function getSubmitLabel(lang) {
    return submitLabel[lang];
}
function getCancelLabel(lang) {
    return cancelLabel[lang];
}
function getSearchModalLabel(lang) {
    return searchModalLabel[lang];
}
function getSumLabel(lang) {
    return sumLabel[lang];
}
function getEstimatedCommissionLabel(lang) {
    return estimatedCommissionLabel[lang];
}
function getEstimatedTotalLabel(lang) {
    return estimatedTotalLabel[lang];
}
function getTradeConfirmedLabelLabel(lang) {
    return tradeConfirmedLabel[lang];
}
function getCommissionLabelLabel(lang) {
    return commissionLabel[lang];
}
function getReestablishingConnectionLabel(lang) {
    return reestablishingConnectionLabel[lang];
}
function getReestablishedConnectionLabel(lang) {
    return reestablishedConnectionLabel[lang];
}
function getUsernamePlaceholder(lang) {
    return usernamePlaceholder[lang];
}
function getForgotPasswordLabel(lang) {
    return forgotPasswordLabel[lang];
}
function getCreateDemoLabel(lang) {
    return createDemoLabel[lang];
}
function getCreateAccountLabel(lang) {
    return createAccountLabel[lang];
}
function getDontHaveAccountLabel(lang) {
    return dontHaveAccountLabel[lang];
}
function getDontHaveDemoAccountLabel(lang) {
    return dontHaveDemoAccountLabel[lang];
}
function getRememberMeLabel(lang) {
    return rememberMeLabel[lang];
}
function getEquitiesLabel(lang) {
    return equitiesLabel[lang];
}
function getBondsLabel(lang) {
    return bondsLabel[lang];
}
function getCompInstrumentsLabel(lang) {
    return compInstrumentsLabel[lang];
}
function getFundsLabel(lang) {
    return fundsLabel[lang];
}
function getReitsLabel(lang) {
    return reitsLabel[lang];
}
function getRightsLabel(lang) {
    return rightsLabel[lang];
}
function getCfdLabel(lang) {
    return cfdLabel[lang];
}
function getMarginLabel(lang) {
    return marginLabel[lang];
}
function getNettingLabel(lang) {
    return nettingLabel[lang];
}
function getRepoLabel(lang) {
    return repoLabel[lang];
}
function getAssetLabel(lang) {
    return assetLabel[lang];
}
function getCountLabel(lang) {
    return countLabel[lang];
}
function getNoMatchingInstrumentsFoundLabel(lang) {
    return noMatchingInstrumentsFoundLabel[lang];
}
function getRefineYourKeywordsLabel(lang) {
    return refineYourKeywordsLabel[lang];
}
function getDetailsLabel(lang) {
    return detailsLabel[lang];
}
function getPhoneLabel(lang) {
    return phoneLabel[lang];
}
function getChartDataForInstrumentUnavailableMessage(lang) {
    return chartDataForInstrumentUnavailableMessage[lang];
}
function getEmailLabel(lang) {
    return emailLabel[lang];
}
function getSortSymbolAscLabel(lang) {
    return sortSymbolAscLabel[lang];
}
function getSortSymbolDescLabel(lang) {
    return sortSymbolDescLabel[lang];
}
function getSortNameAscLabel(lang) {
    return sortNameAscLabel[lang];
}
function getSortNameDescLabel(lang) {
    return sortNameDescLabel[lang];
}
function getSortDefaultLabel(lang) {
    return sortDefaultLabel[lang];
}
function getMenuLabel(lang) {
    return menuLabel[lang];
}
function getWelcomeToEfocsLabel(lang) {
    return welcomeToEfocsLabel[lang];
}
function getLoginToYourAccountLabel(lang) {
    return loginToYourAccountLabel[lang];
}
function getInvalidSessionLabel(lang) {
    return invalidSessionLabel[lang];
}
function getFaceValueLabel(lang) {
    return faceValueLabel[lang];
}
function getSegmentLabel(lang) {
    return segmentLabel[lang];
}
function getReturnLabel(lang) {
    return returnLabel[lang];
}

export default {
    getWalletMenuItem: getWalletMenuItem,
    getDashboardMenuItem: getDashboardMenuItem,
    getTradingMenuItem: getTradingMenuItem,
    getProfileLabel: getProfileLabel,
    getBalanceSummaryLabel: getBalanceSummaryLabel,
    getAccountSummaryLabel: getAccountSummaryLabel,
    getWalletSummaryLabel: getMyWalletLabel,
    getMoneyLabel: getMoneyLabel,
    getPositionsMenuItem: getPositionsMenuItem,
    getPositionsLabel: getPositionsLabel,
    getMyPositionsLabel: getMyPositionsLabel,
    getMyNumberOfInstrumentsLabel: getMyNumberOfInstrumentsLabel,
    getBuyLabel: getBuyLabel,
    getSellLabel: getSellLabel,
    getBuysLabel: getBuysLabel,
    getSellsLabel: getSellsLabel,
    getProcessingLabel: getProcessingLabel,
    getPlacedLabel: getPlacedLabel,
    getFilledLabel: getFilledLabel,
    getAcquiredLabel: getAcquiredLabel,
    getAcceptedLabel: getAcceptedLabel,
    getPassedLabel: getPassedLabel,
    getAnnulledLabel: getAnnulledLabel,
    getExecutedLabel: getExecutedLabel,
    getCompletedLabel: getCompletedLabel,
    getPartialLabel: getPartialLabel,
    getCancelledLabel: getCancelledLabel,
    getSearchPlaceholder: getSearchPlaceholder,
    getStockLabel: getStockLabel,
    getLastDealLabel: getLastDealLabel,
    getTurnoverLabel: getTurnoverLabel,
    getGainersLabel: getGainersLabel,
    getIndexMembersLabel: getIndexMembersLabel,
    getLosersLabel: getLosersLabel,
    getMostTradedLabel: getMostTradedLabel,
    getIndexLabel: getIndexLabel,
    getIndexesLabel: getIndexesLabel,
    getChangeLabel: getChangeLabel,
    getValueLabel: getValueLabel,
    getDeleteWatchlistLabel: getDeleteWatchlistLabel,
    getCloseLabel: getCloseLabel,
    getConfirmLabel: getConfirmLabel,
    getCouldNotSetExchangesMessage: getCouldNotSetExchangesMessage,
    getCouldNotReadMessage: getCouldNotReadMessage,
    getCouldNotReadIndexesMessage: getCouldNotReadIndexesMessage,
    getCouldNotReadWalletMessage: getCouldNotReadWalletMessage,
    getCouldNotReadQuotesMessage: getCouldNotReadQuotesMessage,
    getCouldNotSetSseMessage: getCouldNotSetSseMessage,
    getCouldNotDeleteWatchlistMessage: getCouldNotDeleteWatchlistMessage,
    getAllExchangesLabel: getAllExchangesLabel,
    getАllCurrenciesLabel: getАllCurrenciesLabel,
    getClosedLabel: getClosedLabel,
    getOpenedLabel: getOpenedLabel,
    getExtendedInfoLabel: getExtendedInfoLabel,
    getDepthLabel: getDepthLabel,
    getGraphLabel: getGraphLabel,
    getExchangeLabel: getExchangeLabel,
    getExchangePhaseLabel: getExchangePhaseLabel,
    getExchangePricesLabel: getExchangePricesLabel,
    getPreviousCloseLabel: getPreviousCloseLabel,
    getDailyVolumeLabel: getDailyVolumeLabel,
    getQuantityLabel: getQuantityLabel,
    getBidLabel: getBidLabel,
    getAskLabel: getAskLabel,
    getOneYearReturnLabel: getOneYearReturnLabel,
    getMarketCapLabel: getMarketCapLabel,
    getLastTransactionLabel: getLastTransactionLabel,
    getOrdersLabel: getOrdersLabel,
    getOrdersMenuItem: getOrdersMenuItem,
    getOrderLabel: getOrderLabel,
    getOrderPlacedLabel: getOrderPlacedLabel,
    getOrderTypeLabel: getOrderTypeLabel,
    getPurchasePriceLabel: getPurchasePriceLabel,
    getTradeLabel: getTradeLabel,
    getCouldNotReadPhasesMessage: getCouldNotReadPhasesMessage,
    getCouldNotReadHistoryMessage: getCouldNotReadHistoryMessage,
    getCouldNotSetQuoteMessage: getCouldNotSetQuoteMessage,
    getWatchlistSelectionLabel: getWatchlistSelectionLabel,
    getSaveLabel: getSaveLabel,
    getNewWatchlistNameLabel: getNewWatchlistNameLabel,
    getExistingWatchlistNameLabel: getExistingWatchlistNameLabel,
    getWatchlistLabel: getWatchlistLabel,
    getWatchlistNamePlaceholder: getWatchlistNamePlaceholder,
    getCouldNotUpdateWatchlistMessage: getCouldNotUpdateWatchlistMessage,
    getStockNotAddedMessage: getStockNotAddedMessage,
    getWatchlistNameOccupiedMessage: getWatchlistNameOccupiedMessage,
    getAddedToWatchlistMessage: getAddedToWatchlistMessage,
    getGeneralInformationLabel: getGeneralInformationLabel,
    getSecurityInformationLabel: getSecurityInformationLabel,
    getLanguageLabel: getLanguageLabel,
    getLanguageMenuItem: getLanguageMenuItem,
    getCurrencyLabel: getCurrencyLabel,
    getMainLabel: getMainLabel,
    getCurrencyMenuItem: getCurrencyMenuItem,
    getBaseCurrencyLabel: getBaseCurrencyLabel,
    getUpdateLabel: getUpdateLabel,
    getCurrentPasswordLabel: getCurrentPasswordLabel,
    getPasswordLabel: getPasswordLabel,
    getPasswordMenuItem: getPasswordMenuItem,
    getNewPasswordLabel: getNewPasswordLabel,
    getPasswordPlaceHolder: getPasswordPlaceHolder,
    getRepeatPasswordPlaceHolder: getRepeatPasswordPlaceHolder,
    getCouldNotUpdatePasswordMessage: getCouldNotUpdatePasswordMessage,
    getPasswordUpdatedMessage: getPasswordUpdatedMessage,
    getCouldNotUpdateLanguageMessage: getCouldNotUpdateLanguageMessage,
    getLanguageUpdatedMessage: getLanguageUpdatedMessage,
    getCurrencyUpdatedMessage: getCurrencyUpdatedMessage,
    getPasswordResetMessage: getPasswordResetMessage,
    getConfirmDeleteWatchlistLabel: getConfirmDeleteWatchlistLabel,
    getConfirmCancelOrderLabel: getConfirmCancelOrderLabel,
    getSummaryMenuItem: getSummaryMenuItem,
    getTransactionsLabel: getTransactionsLabel,
    getTransactionsMenuItem: getTransactionsMenuItem,
    getTradesLabel: getTradesLabel,
    getTradesMenuItem: getTradesMenuItem,
    getTypeLabel: getTypeLabel,
    getDateLabel: getDateLabel,
    getTimestampLabel: getTimestampLabel,
    getPriceТotalLabel: getPriceТotalLabel,
    getPriceLabel: getPriceLabel,
    getFeeLabel: getFeeLabel,
    getNoteLabel: getNoteLabel,
    getCodeNameLabel: getCodeNameLabel,
    getQuantityPriceLabel: getQuantityPriceLabel,
    getStatusLabel: getStatusLabel,
    getCurrentBalanceLabel: getCurrentBalanceLabel,
    getBalanceLabel: getBalanceLabel,
    getTotalBalanceLabel: getTotalBalanceLabel,
    getCashBalanceLabel: getCashBalanceLabel,
    getCashLabel: getCashLabel,
    getInSettlementLabel: getInSettlementLabel,
    getFreeFundsLabel: getFreeFundsLabel,
    getGuaranteeDepositLabel: getGuaranteeDepositLabel,
    getExcessFundsLabel: getExcessFundsLabel,
    getAvailableToWithdrawLabel: getAvailableToWithdrawLabel,
    getPositionsBalanceLabel: getPositionsBalanceLabel,
    getAddFundsLabel: getAddFundsLabel,
    getNumberOfSharesLabel: getNumberOfSharesLabel,
    getCouldNotReadOrdersMessage: getCouldNotReadOrdersMessage,
    getCouldNotCancelOrderMessage: getCouldNotCancelOrderMessage,
    getCancelledOrderMessage: getCancelledOrderMessage,
    getCouldNotReadTradesMessage: getCouldNotReadTradesMessage,
    getInformationLabel: getInformationLabel,
    getCodeLabel: getCodeLabel,
    getNameLabel: getNameLabel,
    getMarketPriceLabel: getMarketPriceLabel,
    getMarketValueLabel: getMarketValueLabel,
    getAveragePriceLabel: getAveragePriceLabel,
    getLogoutMenuItem: getLogoutMenuItem,
    getLoginLabel: getLoginLabel,
    getNewNotificationsLabel: getNewNotificationsLabel,
    getClearAllLabel: getClearAllLabel,
    getClearLabel: getClearLabel,
    getViewAllLabel: getViewAllLabel,
    getDemoLabel: getDemoLabel,
    getLiveLabel: getLiveLabel,
    getClientNumberLabel: getClientNumberLabel,
    getMessagesLabel: getMessagesLabel,
    getMessageLabel: getMessageLabel,
    getItemsLabel: getItemsLabel,
    getAddedToDefaultWatchlistMessage: getAddedToDefaultWatchlistMessage,
    getRemovedFromWatchlistMessage: getRemovedFromWatchlistMessage,
    getWatchlistContainsStockMessage: getWatchlistContainsStockMessage,
    getNoItemsInWatchlistMessage: getNoItemsInWatchlistMessage,
    getOrderSentMessage: getOrderSentMessage,
    getOrderNotSentMessage: getOrderNotSentMessage,
    getMarketLabel: getMarketLabel,
    getLimitLabel: getLimitLabel,
    getCodePlaceholder: getCodePlaceholder,
    getDynamicRangeLabel: getDynamicRangeLabel,
    getMinPriceLabel: getMinPriceLabel,
    getMaxPriceLabel: getMaxPriceLabel,
    getTickSizeLabel: getTickSizeLabel,
    getYouHaveLabel: getYouHaveLabel,
    getStocksLabel: getStocksLabel,
    getTotalAmountLabel: getTotalAmountLabel,
    getPurchasedAmountLabel: getPurchasedAmountLabel,
    getAmountLabel: getAmountLabel,
    getTotalEstimatedAmountLabel: getTotalEstimatedAmountLabel,
    getApproximateAmountLabel: getApproximateAmountLabel,
    getEstimatedAmountLabel: getEstimatedAmountLabel,
    getChoseExpiryDateLabel: getChoseExpiryDateLabel,
    getChooseValidityLabel: getChooseValidityLabel,
    getValidityLabel: getValidityLabel,
    getDailyLabel: getDailyLabel,
    getDaysLabel: getDaysLabel,
    getChoseDateLabel: getChoseDateLabel,
    getGoodTillCancelledLabel: getGoodTillCancelledLabel,
    getRestrictionsLabel: getRestrictionsLabel,
    getNoRestrictionsLabel: getNoRestrictionsLabel,
    getAcceptTCLabel: getAcceptTCLabel,
    getSubmitLabel: getSubmitLabel,
    getCancelLabel: getCancelLabel,
    getSearchModalLabel: getSearchModalLabel,
    getSumLabel: getSumLabel,
    getEstimatedCommissionLabel: getEstimatedCommissionLabel,
    getEstimatedTotalLabel: getEstimatedTotalLabel,
    getTradeConfirmedLabelLabel: getTradeConfirmedLabelLabel,
    getCommissionLabelLabel: getCommissionLabelLabel,
    getReestablishingConnectionLabel: getReestablishingConnectionLabel,
    getReestablishedConnectionLabel: getReestablishedConnectionLabel,
    getUsernamePlaceholder: getUsernamePlaceholder,
    getForgotPasswordLabel: getForgotPasswordLabel,
    getCreateDemoLabel: getCreateDemoLabel,
    getCreateAccountLabel: getCreateAccountLabel,
    getDontHaveAccountLabel: getDontHaveAccountLabel,
    getDontHaveDemoAccountLabel: getDontHaveDemoAccountLabel,
    getRememberMeLabel: getRememberMeLabel,
    getEquitiesLabel: getEquitiesLabel,
    getBondsLabel: getBondsLabel,
    getCompInstrumentsLabel: getCompInstrumentsLabel,
    getFundsLabel: getFundsLabel,
    getReitsLabel: getReitsLabel,
    getRightsLabel: getRightsLabel,
    getCfdLabel: getCfdLabel,
    getMarginLabel: getMarginLabel,
    getNettingLabel: getNettingLabel,
    getRepoLabel: getRepoLabel,
    getAssetLabel: getAssetLabel,
    getCountLabel: getCountLabel,
    getNoMatchingInstrumentsFoundLabel: getNoMatchingInstrumentsFoundLabel,
    getRefineYourKeywordsLabel: getRefineYourKeywordsLabel,
    getPerformanceLabel: getPerformanceLabel,
    getDetailsLabel: getDetailsLabel,
    getPhoneLabel: getPhoneLabel,
    getEmailLabel: getEmailLabel,
    getPortfolioLabel: getPortfolioLabel,
    getChartDataForInstrumentUnavailableMessage: getChartDataForInstrumentUnavailableMessage,
    getSortSymbolAscLabel: getSortSymbolAscLabel,
    getSortSymbolDescLabel: getSortSymbolDescLabel,
    getSortNameAscLabel: getSortNameAscLabel,
    getSortNameDescLabel: getSortNameDescLabel,
    getSortDefaultLabel: getSortDefaultLabel,
    getMenuLabel: getMenuLabel,
    getWelcomeToEfocsLabel: getWelcomeToEfocsLabel,
    getLoginToYourAccountLabel: getLoginToYourAccountLabel,
    getInvalidSessionLabel: getInvalidSessionLabel,
    getFaceValueLabel: getFaceValueLabel,
    getSegmentLabel: getSegmentLabel,
    getReturnLabel: getReturnLabel
}
<template>
    <tr v-if="1200 < getWindowsWidth" class="quote-item w-100" valign="middle">
        <!-- Modal add to watchlist -->
        <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addToWatchlistLabel" aria-hidden="true"
            :class="{ 'show': watchlistSelectionModalShow, 'd-block': watchlistSelectionModalActive }">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addToWatchlistLabel">{{ getWatchlistSelectionLabel }}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="btn-group d-flex mtb15" role="group">
                                <input type="radio" class="btn-check" :id="'newwl' + itemStockCode" autocomplete="off"
                                    value="new" v-model="newWatchlistSelected">
                                <label class="btn btn-outline-primary" :for="'newwl' + itemStockCode">{{
                                    getNewWatchlistNameLabel
                                    }}</label>
                                <input type="radio" class="btn-check" :id="'existwl' + itemStockCode" value="old"
                                    v-model="newWatchlistSelected" autocomplete="off">
                                <label class="btn btn-outline-primary" :for="'existwl' + itemStockCode">{{
                                    getExistingWatchlistNameLabel
                                    }}</label>
                            </div>
                        </div>
                        <div class="row" v-show="'new' === newWatchlistSelected">
                            <input :id="'watchlistName' + itemStockCode" type="text" class="form-control"
                                :placeholder="getWatchlistNamePlaceholder" v-model="watchlistName" />
                        </div>
                        <div class="row" v-show="'new' !== newWatchlistSelected">
                            <label :htmlFor="'watchlistName' + itemStockCode">{{ getWatchlistSelectionLabel }}</label>
                            <select :id="'watchlistName' + itemStockCode" class="form-select" v-model="watchlistName">
                                <option v-for="(watchlist) in getWatchlists" :key="watchlist.wln"
                                    :value="watchlist.wln">{{ watchlist.wln }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="updateWatchlist()"
                            :disabled="'' === watchlistName">{{
                            getSaveLabel
                            }}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal()">{{
                            getCloseLabel
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="watchlistSelectionModalActive" class="modal-backdrop fade show"></div>
        <td style="max-width: 3rem;">
            <div>
                <div class="container-fluid mtb15 no-fluid">
                    <div class="row sm-gutters">
                        <div class="col-sm-4 col-md-4">
                            <!-- 2023-08-04 remove custom watchlists -->
                            <!--<div class="icon">
                                <i @click="choseWatchlist" class="icon ion-md-add"></i>
                            </div>-->
                            <div v-show="!isStockPartOfDefaultWatchlist" class="icon">
                                <i @click="updateDefaultWatchlist" class="icon ion-md-add"></i>
                            </div>
                            <div v-show="isStockPartOfWatchlist" class="icon">
                                <i @click="removeFromWatchlist" class="icon ion-md-remove-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td style="width: 10rem;" @click="openExtendedInfo">{{ getExchange.toUpperCase() }}</td>
        <td style="width: 7rem;" @click="openExtendedInfo">{{ stockCode.toString().toUpperCase() }}</td>
        <td style="width: 50rem;" @click="openExtendedInfo">{{ getStockName }}</td>
        <td style="max-width: 6rem;" @click="openExtendedInfo">
            <div v-show="0 < parseFloat(quote?.dif)">
                <span :class="changeColor">+{{ parseNumber(quote?.dif, 4) }}</span>
            </div>
            <div v-show="0 >= parseFloat(quote?.dif)">
                <span :class="changeColor">{{ parseNumber(quote?.dif, 4) }}</span>
            </div>
        </td>
        <td style="max-width: 6rem;" @click="openExtendedInfo">
            <div v-show="0 < parseFloat(quote?.ch)">
                <span :class="changeColor">+{{ parseNumber(quote?.ch, 4) }}%</span>
            </div>
            <div v-show="0 >= parseFloat(quote?.ch)">
                <span :class="changeColor">{{ parseNumber(quote?.ch, 4) }}%</span>
            </div>
        </td>
        <td v-show="isNaN(parseFloat(quote?.ch))" align="center" colspan="3">
            <Preloader></Preloader>
        </td>
        <td style="width: 3rem;" v-show="!isNaN(parseFloat(quote?.ch))" class="stock-data"
            @click="setCodeAndAction('S')">
            <div class="c-rect" :class="buyBackgroundColor">
                <label class="label xsmall-font-size" style="float: right;" :class="buyForegroundColor"
                    v-if="2 !== itemStockCodeTypeId">{{ formatPrice(quote?.b,
                    getExchangeCurrency).replace(' ', '&nbsp;') }}</label>
                <label class="label xsmall-font-size" style="float: right;" :class="buyForegroundColor"
                    v-else>{{ formatPrice(quote?.b,
                    '%').replace(' ', '&nbsp;') }}</label>
            </div>
        </td>
        <td style="width: 3rem;" v-show="!isNaN(parseFloat(quote?.ch))" class="stock-data"
            @click="setCodeAndAction('B')">
            <div class="c-rect xsmall-font-size" :class="sellBackgroundColor">
                <label class="label" style="float: right;" :class="sellForegroundColor" v-if="2 !== itemStockCodeTypeId">{{
                    formatPrice(quote?.a,
                    getExchangeCurrency).replace(' ', '&nbsp;') }}</label>
                <label class="label" style="float: right;" :class="sellForegroundColor" v-else>{{
                    formatPrice(quote?.a,
                    '%').replace(' ', '&nbsp;') }}</label>
            </div>
        </td>
    </tr>
    <div v-else>
        <tr>
            <td @click="openExtendedInfo" class="quote-item">
                <div class="xxsmall-font-size d-flex justify-content-start">
                    <div v-once>{{ stockCode.toString().toUpperCase() }}@{{ getExchange.toUpperCase() }}&nbsp;</div>
                    <div v-if="isNaN(parseFloat(quote?.ch))" :class="changeColor">0%&nbsp;</div>
                    <div v-if="0 < parseFloat(quote?.ch)" :class="changeColor">+{{ parseNumber(quote?.ch, 4) }}%&nbsp;
                    </div>
                    <div v-if="0 >= parseFloat(quote?.ch)" :class="changeColor">{{ parseNumber(quote?.ch, 4) }}%&nbsp;
                    </div>
                    <div v-if="0 < parseFloat(quote?.dif)" :class="changeColor">(+{{ parseNumber(quote?.dif, 4) }})&nbsp;
                    </div>
                    <div v-if="0 >= parseFloat(quote?.dif)" :class="changeColor">({{ parseNumber(quote?.dif, 4) }})&nbsp;
                    </div>
                </div>
                <div class="row">
                    <span style="max-width: 13rem;"><strong>{{ getStockName }}</strong></span>
                </div>
            </td>
            <td v-show="isNaN(parseFloat(quote?.ch))" align="center" colspan="2">
                <Preloader></Preloader>
            </td>
            <td v-show="!isNaN(parseFloat(quote?.ch))" class="stock-data" @click="setCodeAndAction('S')">
                <div class="c-rect" :class="buyBackgroundColor">
                    <label class="label xsmall-font-size" :class="buyForegroundColor"
                        v-if="2 !== itemStockCodeTypeId">{{
                        formatPrice(quote?.b, getExchangeCurrency).replace(' ', '&nbsp;') }}</label>
                    <label class="label xsmall-font-size" :class="buyForegroundColor" v-else>{{
                        formatPrice(quote?.b, '%').replace(' ', '&nbsp;') }}</label>
                </div>
            </td>
            <td v-show="!isNaN(parseFloat(quote?.ch))" class="stock-data" @click="setCodeAndAction('B')">
                <div class="c-rect xsmall-font-size" :class="sellBackgroundColor">
                    <label class="label" :class="sellForegroundColor"
                        v-if="2 !== itemStockCodeTypeId">{{ formatPrice(quote?.a,
                        getExchangeCurrency).replace(' ', '&nbsp;') }}</label>
                    <label class="label xsmall-font-size" :class="buyForegroundColor" v-else>{{
                        formatPrice(quote?.a, '%').replace(' ', '&nbsp;') }}</label>
                </div>
            </td>
            <td v-if="!isStockPartOfDefaultWatchlist" class="icon">
                <i @click="updateDefaultWatchlist" class="icon ion-md-add ms-3"></i>
            </td>
            <td v-else class="icon">
                <i @click="removeFromWatchlist" class="icon ion-md-remove-circle ms-3"></i>
            </td>
        </tr>
    </div>
</template>

<script>
import http from "@/axios/http-common";
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Preloader from "../components/Preloader.vue";
import Translations from "@/lang/translations.js";

const quoteDelta = 0.000001;

export default {
    components: {
        Preloader
    },
    data() {
        return {
            stockCode: this.itemStockCode,
            exchangeCode: this.itemExchangeId,
            stockSegment: this.itemStockCodeSegment,
            stockName: "",
            change: null,
            changeColor: "blackColor",
            diff: null,
            buy: null,
            buyBackgroundColor: "whiteBackgroundColor",
            buyForegroundColor: "blackColor",
            sell: null,
            sellBackgroundColor: "whiteBackgroundColor",
            sellForegroundColor: "blackColor",
            /* active modal dialog for watchlist selection */
            watchlistSelectionModalActive: false,
            /* show modal dialog for watchlist selection */
            watchlistSelectionModalShow: false,
            newWatchlistSelected: "new",
            watchlistName: "",
            quote: null,
            quotesVerifier: null
        }
    },
    props: {
        itemStockCode: {
            required: true,
            type: String
        },
        itemStockCodeSegment: {
            required: true,
            type: String
        },
        itemStockCodeTypeId: {
            required: true,
            type: Number
        },
        itemExchangeId: {
            required: true,
            type: Number
        }
    },
    watch: {
        change: function (newVal) {
            if (newVal > 0) {
                this.changeColor = "green";
            } else if (newVal < 0) {
                this.changeColor = "red";
            } else {
                this.changeColor = "black";
            }
        },
        buy: function (newVal, oldVal) {
            if (parseFloat(newVal) - parseFloat(oldVal) > quoteDelta) {
                this.buyBackgroundColor = "greenBackgroundColor";
                this.buyForegroundColor = "whiteColor";
            } else if (parseFloat(newVal) - parseFloat(oldVal) < quoteDelta) {
                this.buyBackgroundColor = "redBackgroundColor";
                this.buyForegroundColor = "whiteColor";
            } else {
                this.buyForegroundColor = "blackColor";                
            }
            // restore
            setTimeout(() => {
                this.buyBackgroundColor = "whiteBackgroundColor";
                this.buyForegroundColor = "blackColor";
            }, 2000);
        },
        sell: function (newVal, oldVal) {
            if (parseFloat(newVal) - parseFloat(oldVal) > quoteDelta) {
                this.sellBackgroundColor = "greenBackgroundColor";
                this.sellForegroundColor = "whiteColor";
            } else if (parseFloat(newVal) - parseFloat(oldVal) < quoteDelta) {
                this.sellBackgroundColor = "redBackgroundColor";
                this.sellForegroundColor = "whiteColor";
            } else {
                this.buyForegroundColor = "blackColor";
            }
            // restore
            setTimeout(() => {
                this.sellBackgroundColor = "whiteBackgroundColor";
                this.sellForegroundColor = "blackColor";
            }, 2000);
        }
    },
    methods: {     
        formatPrice(val, curr) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage);
        },
        getExchangeCodeString() {
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCodeById(exchanges, this.itemExchangeId);
        },
        getQuote() {
            // extract quote data based on 'code' and 'cMIC' parameters
            let toRet = { b: 0, a: 0, t: 'N/A', ch: 0, dif: 0 };
            const exchangeCodeString = this.getExchangeCodeString();
            if (this.itemStockCode && exchangeCodeString) {
                // get by index as indexes should match
                const quote = UiUtils.findQuote(this.$store, this.itemStockCode, exchangeCodeString)     ;
                if (quote) {
                    toRet = quote;
                }
                this.change = toRet ? toRet.ch : 0;
                this.diff = toRet ? toRet.dif : 0;
                this.buy = toRet ? toRet.b : 0;
                this.sell = toRet ? toRet.a : 0;
                this.timeTaken = toRet ? toRet.t : 0;
            }

            return toRet;
        },
        openExtendedInfo() {
            this.$store.commit("setSelectedTradingCode", this.stockCode);
            this.$store.commit("setSelectedTradingCodeSegment", this.itemStockCodeSegment);
            this.$store.commit("setSelectedTradingCodeShareTypeId", this.itemStockCodeTypeId);
            this.$store.commit("setSelectedTradingExchangeId", this.exchangeCode);
            this.$store.commit("setSelectedTradingStockName", this.getStockName);
            this.$router.push({ name: "ExtendedStock", 
                params: { stockCode: this.stockCode, itemExchangeId: this.itemExchangeId, itemStockCodeSegment: this.itemStockCodeSegment } });
        },
        choseWatchlist() {
            this.watchlistSelectionModalActive = true;
            this.watchlistSelectionModalShow = true;
        },
        closeModal() {
            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async updateWatchlist() {
            this.$store.commit("setAppStateNotReady");
            const updateResponse = await ApiUtils.saveWatchlist(
                http, UiUtils, this.$store, this.getExchange, this.stockCode, "new" === this.newWatchlistSelected, this.watchlistName);
            this.$store.commit("setAppStateReady");
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 1: {
                            // occupied name
                            this.$toast.error(this.getWatchlistNameOccupiedMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistNameOccupiedMessage,
                                error: true
                            });                            
                            break;
                        }
                        case 2: {
                            // watchlist contains element
                            this.$toast.error(this.getWatchlistContainsStockMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistContainsStockMessage,
                                error: true
                            }); 
                            break;
                        }
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getAddedToWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getAddedToWatchlistMessage,
                        error: false
                    });
                }
            }
            this.watchlistSelectionModalActive = false; 
            this.watchlistSelectionModalShow = false;
        },
        async updateDefaultWatchlist() {
            this.$store.commit("setAppStateNotReady");
            const updateResponse = await ApiUtils.updateDefaultWatchlist(http, UiUtils, this.$store, this.getExchange, this.stockCode);
            this.$store.commit("setAppStateReady");
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 2: {
                            // watchlist contains element
                            this.$toast.error(this.getWatchlistContainsStockMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistContainsStockMessage,
                                error: true
                            });
                            break;
                        }
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.cli
                    this.$toast.success(this.getAddedToWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getAddedToWatchlistMessage,
                        error: false
                    });                      
                }
            }

            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async removeFromWatchlist() {
            this.$store.commit("setAppStateNotReady");
            const updateResponse = await ApiUtils.removeFromWatchlist(http, UiUtils, this.$store, this.getExchange, this.stockCode);
            this.$store.commit("setAppStateReady");
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getRemovedFromWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getRemovedFromWatchlistMessage,
                        error: false
                    });
                }
            }
        },
        setCodeAndAction(action) {
            this.$store.commit("setSelectedTradingCode", this.itemStockCode);
            this.$store.commit("setSelectedTradingExchangeId", this.exchangeCode);
            this.$store.commit("setSelectedTradingStockName", this.getStockName);
            this.$store.commit("setSelectedTradingAction", action);
            this.$store.commit("setSelectedTradingCodeSegment", this.itemStockCodeSegment);
            this.$store.commit("setSelectedTradingCodeShareTypeId", this.itemStockCodeTypeId);
            // this is either market or limit order
            this.$store.commit("setOrderIdToUpdate", "");
        },
        parseNumber(num, afterDot = 2) {
            return UiUtils.formatNumber(num, afterDot);
        },
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },          
        getQuotesFromState() {
            return this.$store.getters["getQuotes"];
        },
        getStockName() {
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];
            return UiUtils.getStockName(stocks, indexedStocks, this.stockCode, this.exchangeCode, this.getUserLanguage);             
        },
        getExchangeCurrency() {
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCurrencyById(exchanges, this.itemExchangeId);
        },
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getCloseLabel() {
            return Translations.getCloseLabel(this.getUserLanguage);
        },
        getSaveLabel() {
            return Translations.getSaveLabel(this.getUserLanguage);
        },
        getNewWatchlistNameLabel() {
            return Translations.getNewWatchlistNameLabel(this.getUserLanguage);
        },
        getExistingWatchlistNameLabel() {
            return Translations.getExistingWatchlistNameLabel(this.getUserLanguage);
        },
        getWatchlistNameLabel() {
            return Translations.getWatchlistLabel(this.getUserLanguage);
        }, 
        getWatchlistSelectionLabel() {
            return Translations.getWatchlistSelectionLabel(this.getUserLanguage);
        },
        getWatchlistNamePlaceholder() {
            return Translations.getWatchlistNamePlaceholder(this.getUserLanguage);
        },
        getCouldNotUpdateWatchlistMessage() {
            return Translations.getCouldNotUpdateWatchlistMessage(this.getUserLanguage);
        },
        getRemovedFromWatchlistMessage() {
            return Translations.getRemovedFromWatchlistMessage(this.getUserLanguage);
        },
        getWatchlistNameOccupiedMessage() {
            return Translations.getWatchlistNameOccupiedMessage(this.getUserLanguage);
        },
        getWatchlistContainsStockMessage() {
            return Translations.getWatchlistContainsStockMessage(this.getUserLanguage);
        },
        getAddedToWatchlistMessage() {
            return Translations.getAddedToWatchlistMessage(this.getUserLanguage);
        },
        getWatchlists() {
            const user = this.$store.getters["getUser"];
            return user.wls ? user?.wls : [];
        },
        isStockPartOfWatchlist() {
            if ("" === this.itemStockCode) {
                return false;
            }
            const user = this.$store.getters["getUser"];
            const xCode = this.getExchange;
            let hasItem = false;
            for (let cnt = 0; cnt < user.wls.length; cnt++) {
                const wl = user.wls[cnt];
                hasItem = UiUtils.verifyPairListContainsElement(wl.sc, UiUtils.createExchangeStockCodePairObject(xCode, this.itemStockCode));
                if (hasItem) { break; }
            }
            return hasItem;
        },
        isStockPartOfDefaultWatchlist() {
            if ("" === this.itemStockCode) {
                return false;
            }
            const user = this.$store.getters["getUser"];
            const xCode = this.getExchange;
            let hasItem = false;
            for (let cnt = 0; cnt < user.wls.length; cnt++) {
                const wl = user.wls[cnt];
                if (0 == wl.wln.toString().toLowerCase().localeCompare("default")) {
                    hasItem = UiUtils.verifyPairListContainsElement(wl.sc, UiUtils.createExchangeStockCodePairObject(xCode, this.itemStockCode));
                    if (hasItem) { break; }
                }
            }
            return hasItem;
        },
        getExchange() {
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCodeById(exchanges, this.exchangeCode);
        }
    },
    mounted() {
        // start quotes monitor
        this.quotesVerifier = window.setInterval(function () {
            this.quote = this.getQuote();
        }.bind(this), 500);
    },
    unmounted() {
        if (this.quotesVerifier) {
            clearInterval(this.quotesVerifier);
        }
    }
}
</script>

<style scoped>
    span {
        display: inline-block;
        width: 16rem;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .xxsmall-font-size {
        font-size: xx-small;
    }

    .xsmall-font-size {
        font-size: x-small;
    } 
    
    .medium-font-size {
        font-size: medium;
    }      

    .c-rect {
        display: inline-block;
        min-width: 4.5rem;
        max-width: 4.5rem;
        height: 2rem;
        /*sets height of element*/
        transition: all 0.6s;
        /*sets a transition (for hover effect)*/
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border: 1px solid black;
        border-radius: 8px;
        line-height: 2rem;
        /*for this, it sets vertical alignment*/
    }

    .quote-item {
        cursor: pointer;
    }
    .ion-md-add {
        cursor: pointer;
    }
    .ion-md-add-circle {
        cursor: pointer;
    }
    .ion-md-remove-circle {
        cursor: pointer;
    }

    .greenColor {
        color: #74b21f;
    }

    .redColor {
        color: #ff0000;
    }

    .whiteColor {
        color: #ffffff;
    }

    .blackColor {
        color: #000000;
    }

    .greenBackgroundColor {
        background: #74b21f;
        border: 1px solid #74b21f;
        border-radius: 8px;
    }

    .redBackgroundColor {
        background: #ff0000;
        border: 1px solid #ff0000;
        border-radius: 8px;
    }

    .whiteBackgroundColor {
        background: #ffffff;
        border: 1px solid black;
        border-radius: 8px;
    }    
</style>
/*
 * Company: WHIZ Ltd.
 * Project: TA
 * Module: WebApplication
 * Author: sales@whiz.bg
 */

const STRING_EMPTY = "";
const STRING_NOT_AVAILABLE = "N/A";

/**
 * Formats a number to specific locale (en-US)
 * @param {Number} num 
 * @param {Number} afterDot 
 * @returns String
 */
function formatNumber(num, afterDot = 2) {
    if (!isNaN(num)) {
        // check integer value
        if (Number.isInteger(num) && 0 === parseInt(afterDot)) {
            return num.toLocaleString('en');
        }
        // https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding
        num = ((Number(parseFloat(num)) * 100) / 100).toFixed(afterDot);
    }
    let val = num && !isNaN(parseFloat(num)) ?
        Number(parseFloat(num)).toLocaleString('en') :
        STRING_EMPTY;
    if (STRING_EMPTY === val) {
        return val;
    }
    if (-1 === val.indexOf(".")) {
        let cnt = 0;
        val = val + ".";
        while (cnt !== parseInt(afterDot)) {
            val = val + "0";
            cnt++;
        }
        return val;
    } else {
        while (parseInt(afterDot) + 1 != val.substr(val.indexOf(".")).length) {
            val = val + "0";
        }
        return val;
    }
}

/**
 * Calcualtes total amount of money in user's wallet depending on selected currency
 * @param {UserWalletDTO} userWallet 
 * @param {String} currency 
 * @returns Number
 */
function getTotalMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.m?.forEach(item => {
            if (0 === item.c.toString().toLowerCase().localeCompare("bgn")) {
                totalBgn += item.am_ori;
            } else {
                totalBgn += (item.am_ori * getCurrencyRate(userWallet, item.c));
            }
        });

        return totalBgn;

    }
    // calculate for EUR
    let totalEur = 0;
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    userWallet.m?.forEach(item => {
        totalEur += item.am_ori * 1 / eur2bgn * getCurrencyRate(userWallet, item.c);
    });

    return totalEur;
}

/**
 * Calcualtes all user equities as money value depending on selected currency
 * @param {UserWalletDTO} userWallet 
 * @param {String} currency 
 * @returns Number
 */
function getTotalEquitiesAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.s?.forEach(item => {
            if (2 !== item.stid) {
                totalBgn += item.am * item.mp * getCurrencyRate(userWallet, item.cc);
            } else {
                if (0 === "XBUL".localeCompare(item.ex.toUpperCase())) {
                    const marketPrice = item.mp * 10;
                    totalBgn += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                        ? item.am * marketPrice
                        : item.am * marketPrice * getCurrencyRate(userWallet, item.cc) / eur2bgn;
                } else {
                    totalBgn += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                        ? item.am * item.mp / 100
                        : item.am * item.mp / 100 * getCurrencyRate(userWallet, item.cc) / eur2bgn;
                }
            }
        });

        return totalBgn;
    }
    let totalEur = 0;
    userWallet.s?.forEach(item => {
        if (2 !== item.stid) {
            totalEur += item.am * item.mp * getCurrencyRate(userWallet, item.cc) / eur2bgn;
        } else {
            if (0 === "XBUL".localeCompare(item.ex.toUpperCase())) {
                const marketPrice = item.mp * 10;
                totalEur += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * marketPrice
                    : item.am * marketPrice * getCurrencyRate(userWallet, item.cc) / eur2bgn;
            } else {
                totalEur += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * item.mp / 100
                    : item.am * item.mp / 100 * getCurrencyRate(userWallet, item.cc) / eur2bgn;
            }
        }
    });

    return totalEur;
}

/**
 * Calcualtes XBUL user equities as money value depending on selected currency
 * @param {UserWalletDTO} userWallet 
 * @param {String} currency 
 * @returns Number
 */
function getXbulEquitiesAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.s?.forEach(item => {
            if (0 === "XBUL".localeCompare(item.ex.toUpperCase())) {
                totalBgn += item.am * item.mp * getCurrencyRate(userWallet, item.cc);
            }
        });

        return totalBgn;
    }
    let totalEur = 0;
    userWallet.s?.forEach(item => {
        if (0 === "XBUL".localeCompare(item.ex.toUpperCase())) {
            totalEur += item.am * item.mp * getCurrencyRate(userWallet, item.cc) / eur2bgn;
        }
    });

    return totalEur;
}

/**
 * Calcualtes XETR user equities as money value depending on selected currency
 * @param {UserWalletDTO} userWallet 
 * @param {String} currency 
 * @returns Number
 */
function getXetrEquitiesAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.s?.forEach(item => {
            if (0 === "XETR".localeCompare(item.ex.toUpperCase())) {
                totalBgn += item.am * item.mp * getCurrencyRate(userWallet, item.cc);
            }
        });

        return totalBgn;
    }
    let totalEur = 0;
    userWallet.s?.forEach(item => {
        if (0 === "XETR".localeCompare(item.ex.toUpperCase())) {
            totalEur += item.am * item.mp * getCurrencyRate(userWallet, item.cc) / eur2bgn;
        }
    });

    return totalEur;
}

/**
 * Calcualtes XFRA user equities as money value depending on selected currency
 * @param {UserWalletDTO} userWallet 
 * @param {String} currency 
 * @returns Number
 */
function getXfraEquitiesAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.s?.forEach(item => {
            if (0 === "XFRA".localeCompare(item.ex.toUpperCase())) {
                totalBgn += item.am * item.mp * getCurrencyRate(userWallet, item.cc);
            }
        });

        return totalBgn;
    }
    let totalEur = 0;
    userWallet.s?.forEach(item => {
        if (0 === "XFRA".localeCompare(item.ex.toUpperCase())) {
            totalEur += item.am * item.mp * getCurrencyRate(userWallet, item.cc) / eur2bgn;
        }
    });

    return totalEur;
}

function getExchangeIdByCode(exchanges, code) {
    let toRet = 0;
    if (!exchanges || 0 === exchanges?.length || !code) {
        return toRet;
    }
    let BreakException = {};
    try {
        exchanges.forEach(elem => {
            if (elem.code &&
                0 === code.toString().toLowerCase().localeCompare(elem.code.toString().toLowerCase())) {
                toRet = parseInt(elem.eID);
                // cannot break forEach
                throw BreakException;
            }
        });
    } catch (error) {
        if (BreakException !== error) {
            console.error(error);
        }
    }

    return toRet;
}

function getExchangeCodeById(exchanges, id) {
    if (!exchanges || 0 === exchanges?.length || !id) {
        return STRING_EMPTY;
    }
    const exchange = exchanges.filter(item => parseInt(item.eID) === parseInt(id));
    return !exchange || 0 === exchange?.length ? STRING_EMPTY : exchange[0].code;
}

function getExchangeCurrencyById(exchanges, id) {
    if (!exchanges || 0 === exchanges?.length || !id) {
        return STRING_EMPTY;
    }
    const exchange = exchanges.filter(item => parseInt(item.eID) === parseInt(id));
    return !exchange || 0 === exchange?.length ? STRING_EMPTY : exchange[0].cc;
}

function getExchangeCurrencyByCode(exchanges, code) {
    if (!exchanges || 0 === exchanges?.length || !code) {
        return STRING_EMPTY;
    }
    const exchange = exchanges.filter(item => 0 === code.toLowerCase().localeCompare(item.code.toLowerCase()));
    return !exchange || 0 === exchange?.length ? STRING_EMPTY : exchange[0].cc;
}

function getIndexCurrencyByName(exchanges, code) {
    if (!exchanges || 0 === exchanges?.length || !code) {
        return STRING_EMPTY;
    }
    const exchange = exchanges.filter(item => 0 !== item.indexes.filter(index => 0 === index.n.toLowerCase().localeCompare(code.toLowerCase())).length);
    return !exchange || 0 === exchange?.length ? STRING_EMPTY : exchange[0].cc;
}

/**
 * Returns stock info based on stock code and exchange ID
 * @param {*} stocks 
 * @param {*} exchangeCode 
 * @param {*} stockCode 
 * @returns 
 */
function getStock(stocks, indexedStocks, exchangeCode, stockCode) {
    if (!stocks || 0 === stocks?.length) {
        return null;
    }
    if (!indexedStocks || 0 === indexedStocks?.length) {
        return null;
    }
    if (!stockCode) {
        return null;
    }
    if (!exchangeCode) {
        return null;
    }
    // get stock index by stock code
    const stockIndex = indexedStocks.indexOf(exchangeCode + stockCode);
    if (-1 === stockIndex) {
        return null;
    }
    // get by index as indexes should match
    const stock = stocks[stockIndex];
    return stock ? stock : null;
}

/**
 * Searches and return the name of stocks based on its stock code and exchange ID
 * @param {*} stocks 
 * @param {*} stockCode 
 * @param {*} exchangeCode 
 * @param {*} language 
 * @returns String
 */
function getStockName(stocks, indexedStocks, stockCode, exchangeCode, language) {
    let toRet = STRING_NOT_AVAILABLE;
    const stock = getStock(stocks, indexedStocks, exchangeCode, stockCode);
    if (stock) {
        if (0 === "BG".localeCompare(language.toString().toUpperCase())) {
            toRet = stock.nBG ? stock.nBG : stock.nEN;
        } else {
            toRet = stock.nEN;
        }
    }

    return toRet;
}

function getStockCurrency(stocks, indexedStocks, stockCode, exchangeCode) {
    const stock = getStock(stocks, indexedStocks, exchangeCode, stockCode);

    return stock
        ? stock.cc.toString().toUpperCase()
        : STRING_NOT_AVAILABLE;
}

/**
 * Returns specific currency rate from wallet data
 * @param {*} userWallet 
 * @param {*} currency 
 * @returns float
 */
function getCurrencyRate(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    // calculate for BGN
    const rate = userWallet.r?.filter(item => {
        return 0 === item.c.toString().toLowerCase().localeCompare(currency.toString().toLowerCase() + "/bgn");
    });
    if (!rate || 0 === rate?.length) {
        return 0;
    }

    return parseFloat(rate[0].r);
}

function createExchangeStockCodePairObject(exchangeCode, stockCode) {
    return {
        xc: exchangeCode,
        sc: stockCode
    };
}

function combineExchangeStockPairFromObject(pair) {
    return pair.xc + pair.sc;
}

function combineExchangeStockPair(exchangeCode, stockCode) {
    return exchangeCode + stockCode;
}

function extractExchangeCodeFromPairObject(pair) {
    return pair && pair.xc ? pair.xc : null;
}

function extractStockCodeFromPairObject(pair) {
    return pair && pair.sc && null !== pair.sc ? pair.sc : null;
}

function compareExchangeStockPairs(pair1, pair2) {
    // must be valid
    if (!pair1) {
        return false;
    }
    if (!pair2) {
        return false;
    }
    try {
        return 0 === pair1.xc.toString().toUpperCase().localeCompare(pair2.xc.toString().toUpperCase()) &&
            0 === pair1.sc.toString().toUpperCase().localeCompare(pair2.sc.toString().toUpperCase())
    } catch (error) {
        console.error(error);
        return false;
    }
}

function extractStocksInWatchlist(stocks, watchlist, exchanges) {
    let mappedStocks = [];
    try {
        if (!stocks || 0 === stocks?.length) {
            return mappedStocks;
        }
        if (!exchanges || 0 === exchanges?.length) {
            return mappedStocks;
        }
        if (!watchlist || !watchlist.sc || 0 === watchlist?.sc?.length) {
            return mappedStocks;
        }
        let BreakException = {};
        watchlist.sc.forEach(pair => {
            try {
                const xCodeId = getExchangeIdByCode(exchanges, pair.xc);
                stocks.forEach(elem => {
                    if (elem.code &&
                        0 === pair.sc.toString().toLowerCase().localeCompare(elem.code.toString().toLowerCase()) &&
                        parseInt(xCodeId) === parseInt(elem.cMIC)) {
                        mappedStocks.push(elem);
                        // cannot break forEach
                        throw BreakException;
                    }
                });
            } catch (error) {
                if (BreakException !== error) {
                    console.error(error);
                }
            }
        });
    } catch (error) {
        console.error(error);
    }

    return mappedStocks;
}

function verifyPairListContainsElement(list, pair) {
    if (!list || 0 === list?.length) {
        return false;
    }
    if (!pair) {
        return false;
    }
    try {
        let pairInList = list.filter(elem => {
            return 0 === pair.sc.toString().toLowerCase().localeCompare(elem.sc.toString().toLowerCase()) &&
                0 === pair.xc.toString().toLowerCase().localeCompare(elem.xc.toString().toLowerCase());
        });
        return pairInList && 0 < pairInList?.length;
    } catch (error) {
        console.error(error);
    }

    return false;
}

function getUserWatchlists(user) {
    let watchlistsToDisplay = [];
    // add default
    user?.wls?.forEach(wl => {
        if (0 === 'default'.localeCompare(wl.wln.toString().toLowerCase())) {
            watchlistsToDisplay.push(wl);
        }
    });
    // add all others
    user?.wls?.forEach(wl => {
        if (0 !== 'default'.localeCompare(wl.wln.toString().toLowerCase())) {
            watchlistsToDisplay.push(wl);
        }
    });
    return watchlistsToDisplay;
}

function getIndexedWatchlists(exchanges, indexesWatchlists, selectedExchange) {
    // filter by exchange
    if (0 !== parseInt(selectedExchange)) {
        // XFRA should display XETR indexes
        const ex = 3 === parseInt(selectedExchange)
            ? 2
            : parseInt(selectedExchange);
        let filteredIndexesWatchlist = [];
        for (let cnt = 0; cnt < exchanges.length; cnt++) {
            for (let exCnt = 0; exCnt < exchanges[cnt].indexes.length; exCnt++) {
                for (let wlsCnt = 0; wlsCnt < indexesWatchlists.length; wlsCnt++) {
                    if (0 === indexesWatchlists[wlsCnt].wln.toString().toUpperCase().localeCompare(exchanges[cnt].indexes[exCnt].n.toString().toUpperCase())) {
                        if (ex === parseInt(exchanges[cnt].eID)) {
                            filteredIndexesWatchlist.push(indexesWatchlists[wlsCnt]);
                            break;
                        }
                    }
                }
            }
        }
        return filteredIndexesWatchlist;
    }
    return indexesWatchlists;
}

function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

function parseUnixMsTime(itemTime) {
    if (!itemTime) {
        return STRING_EMPTY;
    }
    try {
        var itemDate = new Date(itemTime);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = itemDate.getFullYear();
        var month = months[itemDate.getMonth()];
        var date = "0" + itemDate.getDate();
        var hour = "0" + itemDate.getHours();
        var min = "0" + itemDate.getMinutes();
        var sec = "0" + itemDate.getSeconds();
        var time = date.toString().slice(-2) + ' ' + month + ' ' + year.toString().slice(-2) + ', ' + hour.toString().slice(-2) + ':' + min.toString().slice(-2) + ':' + sec.toString().slice(-2);
        return time;
    } catch (error) {
        // do nothing
    }
    return STRING_EMPTY;
}

function parseUnixTime(itemTime) {
    if (!itemTime) {
        return STRING_EMPTY;
    }
    return parseUnixMsTime(itemTime * 1000);
}

/**
 * Sorts array by date field
 * @param {*} items 
 * @param {*} dateType 
 * @param {*} isMs 
 * @returns array
 */
function sortByDate(items, dateType, isMs) {
    return items.sort(function (a, b) {
        if (a[dateType] && b[dateType]) {
            var date1 = true === isMs ? new Date(a[dateType]) : new Date(a[dateType] * 1000);
            var date2 = true === isMs ? new Date(b[dateType]) : new Date(b[dateType] * 1000);
            return date1.getTime() - date2.getTime();
        }
        return 0;
    });
}

function formatPrice(val, curr, userLang, autoDigits = null) {
    if (isNaN(parseFloat(val))) {
        val = 0;
    }
    let afterDot;
    if (!autoDigits) {
        afterDot = calculateNumberOfMeaningfulDigits(val);
        if (0 === val) {
            afterDot = 0;
        }
    } else {
        afterDot = Number(parseInt(autoDigits));
    }
    if (0 === "BG".localeCompare(userLang) && 0 === curr.toString().toUpperCase().localeCompare("BGN")) {
        return formatNumber(val, afterDot) + " лв.";
    }
    switch (curr.toString().toUpperCase()) {
        case "EUR": return "€" + formatNumber(val, afterDot);
        case "USD": return "$" + formatNumber(val, afterDot);
        case "JPY": return "¥" + formatNumber(val, afterDot);
        case "CHF": return "₣" + formatNumber(val, afterDot);
        case "GBP": return "£" + formatNumber(val, afterDot);
    }
    // EN
    return curr.toString().toUpperCase() + " " + formatNumber(val, afterDot);
}

function findQuote(store, stockCode, exchangeCode) {
    const quotes = store.getters["getQuotes"];
    const matchedItems = quotes.filter(item => {
        return 0 === item.xc.toString().toLowerCase().localeCompare(exchangeCode.toString().toLowerCase())
            && 0 === item.sc.toString().toLowerCase().localeCompare(stockCode.toString().toLowerCase())
    });
    return matchedItems ? matchedItems[0] : null;
}

function calculateNumberOfMeaningfulDigits(quotePrice) {
    let precision = 0;
    if (isNaN(parseFloat(quotePrice)) || 0 === parseFloat(quotePrice)) {
        return precision;
    }
    if (10.0 > parseFloat(quotePrice)) {
        precision = 4;
    } else if (100.0 > parseFloat(quotePrice)) {
        precision = 3;
    } else if (1000.0 > parseFloat(quotePrice)) {
        precision = 2;
    } else if (10000.0 > parseFloat(quotePrice)) {
        precision = 1;
    }

    return precision;
}

function getCurrencyRatio(userWallet, stockCurrency, requestedCurrency) {
    const reqCurrency = requestedCurrency + '/bgn';
    // requested currency ration
    const reqRatios = userWallet.r?.filter(item => {
        return 0 === item.c.toLowerCase().localeCompare(reqCurrency.toLowerCase());
    });
    if (!reqRatios) {
        return 0;
    }
    const rt = stockCurrency + '/bgn';
    const ratios = userWallet.r?.filter(item => {
        return 0 === item.c.toLowerCase().localeCompare(rt.toLowerCase());
    });
    if (!ratios) {
        return 0;
    }
    return !ratios ? 0 : parseFloat(ratios[0].r) / parseFloat(reqRatios[0].r);
}

function extractExchangeEquitiesValue(exchangeCode, currency, userWallet) {
    // no wallet, no stocks in wallet, no ratio in wallet
    if (!userWallet || !userWallet.s || !userWallet.r) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchangeCode.toLowerCase());
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }
    // calculate all assets in requested exchange in the requested currency
    let assetsInCurrency = 0;
    exchangeStocks.forEach(item => {
        // bonds are in percent
        if (2 !== parseInt(item.stid)) {
            assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                ? item.am * item.mp
                : item.am * item.mp * getCurrencyRatio(userWallet, item.cc, currency);
        } else {
            if (0 === "xbul".localeCompare(exchangeCode.toLowerCase())) {
                /**
                1. XBUL
                a) бройка 
                единицата за търгуване е 1 номинал (1000)
                стойност 1 означава 1 номинал (face value 1000)
                стойност 2 означава 2 номинала (face value 2000)
                b) цена
                парите платени за 1 номинал
                стойност 1002.50 означава 100.25%, защото 1002.50 = 1000 * 100.25%                
                 */
                const marketPrice = item.mp * 10;
                assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * marketPrice
                    : item.am * marketPrice * getCurrencyRatio(userWallet, item.cc, currency);
            } else {
                /**
                3. XFRA
                a) бройка 
                единицата за търгуване е 1/10 от номинала (100)
                стойност 1 означава 0.1 номинал (face value 100)
                стойност 10 означава 1.0 номинала (face value 1000)
                стойност 11 означава 1.1 номинала (face value 1100)
                b) цена
                реалната цена в проценти
                стойност 100.25 означава 100.25%
                 */
                assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * item.mp / 100
                    : item.am * item.mp / 100 * getCurrencyRatio(userWallet, item.cc, currency);
            }
        }
    });

    return assetsInCurrency;
}

function extractExchangeEquitiesAverageValue(exchange, currency, userWallet) {
    // no wallet, no stocks in wallet, no ratio in wallet
    if (!userWallet || !userWallet.s || !userWallet.r) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchange.toLowerCase());
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }
    // calculate all assets in requested exchange in the requested currency
    let assetsInCurrency = 0;
    exchangeStocks.forEach(item => {
        const price = item.ap === 0
            ? item.mp
            : item.ap;
        // bonds are in percent
        if (2 !== parseInt(item.stid)) {
            assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                ? item.am * price
                : item.am * price * getCurrencyRatio(userWallet, item.cc, currency);
        } else {
            if (0 === "xbul".localeCompare(exchange.toLowerCase())) {
                /**
                1. XBUL
                a) бройка 
                единицата за търгуване е 1 номинал (1000)
                стойност 1 означава 1 номинал (face value 1000)
                стойност 2 означава 2 номинала (face value 2000)
                b) цена
                парите платени за 1 номинал
                стойност 1002.50 означава 100.25%, защото 1002.50 = 1000 * 100.25%                
                 */
                assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * price
                    : item.am * price * getCurrencyRatio(userWallet, item.cc, currency);
            } else {
                /**
                3. XFRA
                a) бройка 
                единицата за търгуване е 1/10 от номинала (100)
                стойност 1 означава 0.1 номинал (face value 100)
                стойност 10 означава 1.0 номинала (face value 1000)
                стойност 11 означава 1.1 номинала (face value 1100)
                b) цена
                реалната цена в проценти
                стойност 100.25 означава 100.25%
                 */
                assetsInCurrency += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? item.am * price / 100
                    : item.am * price / 100 * getCurrencyRatio(userWallet, item.cc, currency);
            }
        }
    });

    return assetsInCurrency;
}

function extractExchangeEquitiesCount(exchange, userWallet) {
    // no wallet, no stocks in wallet
    if (!userWallet || !userWallet.s) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchange.toLowerCase());
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }

    // calculate assets in requested exchange
    let assetsInCurrency = 0;
    exchangeStocks.forEach(item => {
        assetsInCurrency += item.am;
    });

    return assetsInCurrency;
}

function extractExchangePositionsCount(exchange, userWallet) {
    // no wallet, no stocks in wallet
    if (!userWallet || !userWallet.s) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchange.toLowerCase())
            && 0 < item?.am;
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }

    return exchangeStocks.length;
}

function getTotalInstrumentsCountByType(type, exchange, userWallet) {
    // no wallet, no stocks in wallet
    if (!userWallet || !userWallet.s) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchange.toLowerCase());
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }
    let assetsCount = 0;
    exchangeStocks.forEach(element => {
        if (parseInt(type) === element.stid) {
            assetsCount += element.am;
        }
    });
    return assetsCount;
}

function getTotalInstrumentsValueByType(type, exchange, userWallet) {
    // no wallet, no stocks in wallet
    if (!userWallet || !userWallet.s) {
        return 0;
    }
    // get stocks for explicit exchange
    const exchangeStocks = userWallet.s.filter(item => {
        return 0 === item?.ex?.toLowerCase().localeCompare(exchange.toLowerCase());
    });
    // no shares in this exchange
    if (!exchangeStocks) {
        return 0;
    }
    let assetsCount = 0;
    exchangeStocks.forEach(element => {
        if (parseInt(type) === element.stid) {
            assetsCount += element.mp;
        }
    });
    return assetsCount;
}

function getTradeAvgPrice(item) {
    if (!item) {
        return 0;
    }
    // bonds are special case and have their avg price different depending on exchange
    if (2 !== item.stid) {
        return item.ap;
    }
    if (0 === "xbul".localeCompare(item.ex.toString().toLowerCase())) {
        /**
            парите платени за 1 номинал (1000)
            стойност 1002.50 означава 100.25%, защото 1002.50 = 1000 * 100.25%
         */
        return item.ap / 10;
    }

    /**
        реалната цена в проценти
        стойност 100.25 означава 100.25% (от 1000)
     */
    return item.ap;
}

function getTradePurchasePrice(item) {
    if (!item) {
        return 0;
    }
    const price = 0 === item.ap
        ? item.mp
        : item.ap;

    // bonds are special case and have their avg price different depending on exchange
    if (2 !== item.stid) {
        return price * item.am;
    }
    if (0 === "xbul".localeCompare(item.ex.toString().toLowerCase())) {
        /**
            парите платени за 1 номинал (1000)
            единицата за търгуване е 1 номинал (1000)
            стойност 1002.50 означава 100.25%, защото 1002.50 = 1000 * 100.25%
         */
        return price * item.am;
    }

    /**
        реалната цена в проценти
        единицата за търгуване е 1/10 от номинала (100)
        стойност 100.25 означава 100.25% (от 1000)
     */
    return price * item.am / 100;
}

function getTradeMarketPrice(item) {
    if (!item) {
        return 0;
    }
    // bonds are special case and have their avg price different depending on exchange
    if (2 !== item.stid) {
        return item.mp * item.am;
    }

    if (0 === "xbul".localeCompare(item.ex.toString().toLowerCase())) {
        /**
            парите платени за 1 номинал (1000)
            единицата за търгуване е 1 номинал (1000)
            стойност 1002.50 означава 100.25%, защото 1002.50 = 1000 * 100.25%
         */
        return item.mp * 10 * item.am;
    }

    /**
        реалната цена в проценти
        единицата за търгуване е 1/10 от номинала (100)
        стойност 100.25 означава 100.25% (от 1000)
     */
    return item.mp * item.am / 100;
}

function getTotalEquitiesInBuySettlementAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    var sttlBuyInBgn = 0;
    var sttlBuyInEur = 0;

    userWallet.s?.forEach(item => {
        if (0 === "eur".localeCompare(item.cc.toString().toLowerCase())) {
            sttlBuyInEur += item.sttl_buy || 0;
        } else if (0 === "bgn".localeCompare(item.cc.toString().toLowerCase())) {
            sttlBuyInBgn += item.sttl_buy || 0;
        }
    });

    const eur2bgn = getCurrencyRate(userWallet, "eur");

    return 0 === "BGN".localeCompare(currency.toUpperCase())
        ? sttlBuyInBgn + sttlBuyInEur * eur2bgn
        : sttlBuyInEur + sttlBuyInBgn / eur2bgn;
}

function getTotalEquitiesInSellSettlementAsMoney(userWallet, currency) {
    if (!userWallet) {
        return 0;
    }
    var sttlSellInBgn = 0;
    var sttlSellInEur = 0;

    userWallet.s?.forEach(item => {
        if (0 === "eur".localeCompare(item.cc.toString().toLowerCase())) {
            sttlSellInEur += item.sttl_sell || 0;
        } else if (0 === "bgn".localeCompare(item.cc.toString().toLowerCase())) {
            sttlSellInBgn += item.sttl_sell || 0;
        }
    });

    const eur2bgn = getCurrencyRate(userWallet, "eur");

    return 0 === "BGN".localeCompare(currency.toUpperCase())
        ? sttlSellInBgn + sttlSellInEur * eur2bgn
        : sttlSellInEur + sttlSellInBgn / eur2bgn;
}

function getGuaranteeDeposit(userWallet, currency) {
    /**
        блокирани средства породени от бъдещи покупки
        това се чакащи поръчки, лимитирани или пазарни, които
        - не са изпълнени въобще, или
        - са частично изпълнени
        за лимитирани поръчки се взема цената от поръчката
        за пазарни поръчки се взема пазарната цена
        включва се и комисионата
        те са със знак минус
        цената се увеличава с 2% като гаранция, че всички разходи на брокера и борсата ще бъдат покрити
     */
    if (!userWallet) {
        return 0;
    }

    /*    var gDepositInBgn = 0;
        var gDepositInEur = 0;
    
        userWallet.s?.forEach(item => {
            if (0 === "b".localeCompare(item.act.toString().toLowerCase())) {
                const calc = item.cnt * (item.pr || 0) * 1.02 + item.fee || 0;
                if (0 === "eur".localeCompare(item.cc.toString().toLowerCase())) {
                    gDepositInEur += calc || 0;
                } else if (0 === "bgn".localeCompare(item.cc.toString().toLowerCase())) {
                    gDepositInBgn += calc || 0;
                }
            }
        });
    
        const eur2bgn = getCurrencyRate(userWallet, "eur");
    
        return 0 === "BGN".localeCompare(currency.toUpperCase())
            ? gDepositInBgn + gDepositInEur * eur2bgn
            : gDepositInEur + gDepositInBgn / eur2bgn;    */

    // calculate for BGN
    const eur2bgn = getCurrencyRate(userWallet, "eur");
    if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        let totalBgn = 0;
        userWallet.orders?.forEach(item => {
            if (0 === "b".localeCompare(item.act.toString().toLowerCase())) {
                const calc = item.cnt * (item.pr || 0) * 1.02 + item.fee || 0;
                totalBgn += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                    ? calc
                    : calc * getCurrencyRate(userWallet, item.cc);
            }
        });
        return totalBgn;
    }
    let totalEur = 0;
    userWallet.orders?.forEach(item => {
        if (0 === "b".localeCompare(item.act.toString().toLowerCase())) {
            const calc = item.cnt * (item.pr || 0) * 1.02 + item.fee || 0;
            totalEur += 0 === item.cc.toLowerCase().localeCompare(currency.toLowerCase())
                ? calc
                : calc * getCurrencyRate(userWallet, item.cc) / eur2bgn;
        }
    });

    return totalEur;
}

export default {
    formatNumber: formatNumber,
    getTotalMoney: getTotalMoney,
    getTotalEquitiesAsMoney: getTotalEquitiesAsMoney,
    getXbulEquitiesAsMoney: getXbulEquitiesAsMoney,
    getXetrEquitiesAsMoney: getXetrEquitiesAsMoney,
    getXfraEquitiesAsMoney: getXfraEquitiesAsMoney,
    getExchangeIdByCode: getExchangeIdByCode,
    getExchangeCodeById: getExchangeCodeById,
    getExchangeCurrencyById: getExchangeCurrencyById,
    getExchangeCurrencyByCode: getExchangeCurrencyByCode,
    getIndexCurrencyByName: getIndexCurrencyByName,
    getStock: getStock,
    getStockName: getStockName,
    getCurrencyRate: getCurrencyRate,
    createExchangeStockCodePairObject: createExchangeStockCodePairObject,
    combineExchangeStockPairFromObject: combineExchangeStockPairFromObject,
    combineExchangeStockPair: combineExchangeStockPair,
    extractExchangeCodeFromPairObject: extractExchangeCodeFromPairObject,
    extractStockCodeFromPairObject: extractStockCodeFromPairObject,
    compareExchangeStockPairs: compareExchangeStockPairs,
    extractStocksInWatchlist: extractStocksInWatchlist,
    verifyPairListContainsElement: verifyPairListContainsElement,
    getUserWatchlists: getUserWatchlists,
    getIndexedWatchlists: getIndexedWatchlists,
    isInt: isInt,
    isFloat: isFloat,
    parseUnixTime: parseUnixTime,
    parseUnixMsTime: parseUnixMsTime,
    sortByDate: sortByDate,
    formatPrice: formatPrice,
    findQuote: findQuote,
    calculateNumberOfMeaningfulDigits: calculateNumberOfMeaningfulDigits,
    extractExchangeEquitiesValue: extractExchangeEquitiesValue,
    extractExchangeEquitiesCount: extractExchangeEquitiesCount,
    extractExchangePositionsCount: extractExchangePositionsCount,
    extractExchangeEquitiesAverageValue: extractExchangeEquitiesAverageValue,
    getTotalInstrumentsCountByType: getTotalInstrumentsCountByType,
    getTotalInstrumentsValueByType: getTotalInstrumentsValueByType,
    getTradeAvgPrice: getTradeAvgPrice,
    getTradePurchasePrice: getTradePurchasePrice,
    getTradeMarketPrice: getTradeMarketPrice,
    getTotalEquitiesInBuySettlementAsMoney: getTotalEquitiesInBuySettlementAsMoney,
    getTotalEquitiesInSellSettlementAsMoney: getTotalEquitiesInSellSettlementAsMoney,
    getCurrencyRatio: getCurrencyRatio,
    getGuaranteeDeposit: getGuaranteeDeposit,
    getStockCurrency: getStockCurrency
}